@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

$static-page-input-text-max-width: 400px;
$static-page-input-radio-accent-color: #21ac0b;

.CompetitionForm {
  border: 1px solid var(--dds-primary);
  border-radius: $dds-border-radius-global;
  padding: $dds-spacing-3;

  h4 {
    margin-bottom: $dds-spacing-2;
  }

  .TextInput {
    margin-bottom: $dds-spacing-3_5;
    max-width: $static-page-input-text-max-width;
  }
}

.CompetitionForm-Text {
  margin-bottom: $dds-spacing-1;
}

.CompetitionForm-RadioWrapper {
  margin-bottom: $dds-spacing-4;

  .Error {
    margin-top: -$dds-spacing-1;
  }
}

.CompetitionForm-RadioLabel {
  align-items: center;
  display: flex;
  margin-bottom: $dds-spacing-1;
}

.CompetitionForm-Radio {
  @include dds-box($dds-sizing-2_5);

  accent-color: $static-page-input-radio-accent-color;
  margin-right: $dds-spacing-1;
}

.CompetitionForm-CheckboxAcceptWrapper {
  margin-bottom: $dds-spacing-3;
}

.CompetitionForm-CheckboxSubscribeWrapper {
  margin-bottom: $dds-spacing-2;
}

.CompetitionForm-NewsletterDropdown {
  margin-bottom: $dds-spacing-3_5;
  margin-left: $dds-spacing-3_5;
  max-width: calc(#{$static-page-input-text-max-width} - #{$dds-spacing-3_5});
}

.CompetitionForm-Alert {
  margin-top: $dds-spacing-3_5;
  max-width: $static-page-input-text-max-width;
}
