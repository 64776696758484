@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

$sold-out-box-width: 595px;

.SoldOutBox {
  @include dds-pos(absolute, $top: 50%, $left: 50%);

  background-color: var(--dds-white);
  border-radius: $dds-border-radius-global * 2;
  box-shadow: 0 $dds-sizing-0_5 $dds-sizing-2 0 rgb(0 0 0 / 15%);
  max-width: calc(100% - #{$dds-sizing-4});
  padding: 0 $dds-spacing-2 $dds-spacing-2;
  transform: translate(-50%, -50%);
  width: $sold-out-box-width;
  z-index: 1;

  @include media-breakpoint-up(lg) {
    padding: 0 $dds-spacing-6 $dds-spacing-5;
  }
}

.SoldOutBox-CloseButton {
  @include dds-pos(absolute, $top: $dds-spacing-1, $right: $dds-spacing-1);

  svg {
    @include dds-box($dds-sizing-4);

    fill: var(--dds-text-body);
  }
}

.SoldOutBox-LabelWrapper {
  margin: -$dds-spacing-2_75 0 $dds-spacing-2;
  text-align: center;

  @include media-breakpoint-up(lg) {
    margin: -$dds-spacing-3 0 $dds-spacing-3;
  }
}

.SoldOutBox-Label {
  @include dds-type-scale(1);

  background-color: var(--dds-neon-yellow);
  border-radius: $dds-border-radius-global * 2;
  display: inline-block;
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  letter-spacing: rem(-0.54px);
  line-height: $dds-line-height-list;
  padding: $dds-sizing-1_5 $dds-sizing-3;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(3);

    letter-spacing: rem(-0.68px);
    line-height: $dds-line-height-base;
  }
}

.SoldOutBox-Text {
  margin-bottom: $dds-spacing-2;

  @include media-breakpoint-only(md) {
    @include dds-type-scale(-1);

    line-height: $dds-line-height-list;
    margin-bottom: $dds-spacing-2;
  }
}

.SoldOutBox-Newsletter {
  .Button {
    margin: 0 auto;
    white-space: nowrap;
  }

  .Newsletter-Alert {
    @include media-breakpoint-only(md) {
      margin-top: $dds-spacing-1_5;
    }
  }
}

