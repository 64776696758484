@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.RecommendedProducts {
  padding-bottom: $dds-spacing-6;
  padding-top: $dds-spacing-6;

  .Swiper-Slide {
    @include dds-slide-margin-right;

    width: 90%;

    @include media-breakpoint-up(md) {
      width: 49%;
    }

    @include media-breakpoint-up(lg) {
      width: 23.3%;
    }
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: $dds-spacing-12;
    padding-top: $dds-spacing-8;
  }
}

.RecommendedProducts-Title {
  margin-bottom: $dds-spacing-2;

  @include media-breakpoint-up(md) {
    margin-bottom: $dds-spacing-4_5;
  }
}
