@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

$variant-image-mobiles-width: $dds-sizing-8;
$variant-image-tablet-desktop-width: $dds-sizing-12;

.Variant {
  border: 1px solid var(--dds-gray-300);
  border-radius: $dds-border-radius-global * 2;
  cursor: pointer;
  margin-bottom: $dds-spacing-1;
  min-height: calc(#{$dds-sizing-12} + 2px);
  padding: $dds-sizing-2;
  position: relative;

  @include media-breakpoint-up(md) {
    flex: 0 1 calc(33.3% - #{$dds-spacing-3});
    margin-bottom: $dds-spacing-3;
    margin-right: $dds-spacing-3;
    min-height: auto;
  }
}

.Variant--Selected {
  border-color: var(--dds-primary);
  box-shadow: inset 0 0 0 1px var(--dds-primary);
}

.Variant-Image {
  @include dds-pos(absolute, $top: $dds-spacing-2, $left: $dds-spacing-2);

  position: absolute;
  text-align: center;

  img {
    max-width: $variant-image-mobiles-width;
  }

  @include media-breakpoint-up(md) {
    @include dds-pos(unset, $top: 0, $left: 0);

    margin-bottom: $dds-spacing-1;

    img {
      max-width: $variant-image-tablet-desktop-width;
    }
  }
}

.Variant--WithoutImage {
  min-height: auto;

  .Variant-Name {
    padding-left: 0;
  }

  .Variant-Price {
    padding-left: 0;
  }
}

.Variant-Name {
  @include dds-type-scale(-1);

  letter-spacing: rem(-0.43px);
  line-height: $dds-line-height-list;
  padding-left: $dds-spacing-10;

  @include media-breakpoint-up(md) {
    padding-left: 0;
  }
}

.Variant-Price {
  padding-left: $dds-spacing-10;

  @include media-breakpoint-up(md) {
    padding-left: 0;
  }
}

.Variant--SoldOutOrReserved {
  cursor: not-allowed;
  opacity: 0.6;
  overflow: hidden;
  position: relative;

  &::after {
    @include dds-pos(absolute, $left: $dds-spacing-20, $top: -20%);

    background-color: var(--dds-gray-600);
    content: '';
    height: 140%;
    transform: rotate(35deg);
    width: $dds-sizing-0_5;

    @include media-breakpoint-up(md) {
      left: 50%;
    }
  }
}
