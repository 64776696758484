@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.News {
  background-color: var(--dds-deal-deal-page-news-bg);
  padding-bottom: $dds-spacing-4;
  padding-top: $dds-spacing-3;

  .Swiper-Slide {
    @include dds-slide-margin-right;

    width: 90%;

    @include media-breakpoint-up(md) {
      width: 40%;
    }

    @include media-breakpoint-up(lg) {
      width: 48.8%;
    }
  }

  .Slider-PaginationBullet {
    background-color: var(--dds-deal-deal-page-news-slider-pagination-bullet-bg);
  }

  .Slider-ArrowIcon {
    fill: var(--dds-deal-deal-page-news-slider-arrow-icon-fill);
  }

  @include media-breakpoint-up(md) {
    padding-top: $dds-spacing-4;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: $dds-spacing-8;
    padding-top: $dds-spacing-8;
  }
}

.News-Title {
  color: var(--dds-deal-deal-page-news-title-color);
  margin-bottom: $dds-spacing-1_5;

  @include media-breakpoint-up(md) {
    margin-bottom: $dds-spacing-4;
  }
}

.News-Alert {
  background-color: transparent;
  color: var(--dds-deal-deal-page-news-title-color);
  padding: 0;

  .Alert-Content {
    display: flex;
  }

  .Alert-Icon {
    fill: var(--dds-deal-deal-page-news-title-color);
  }
}

.News-RetryButton {
  color: var(--dds-deal-deal-page-news-title-color);
  font-family: $dds-font-family-base;
  font-weight: $dds-font-weight-semibold;
  margin-left: $dds-spacing-1_5;
  text-decoration: underline;
  text-transform: none;
}
