@import '~dd-client/site/common/styles/globals';

.VideoButton {
  background-color: var(--dds-common-video-button-bg);
  border: 0;
  border-radius: 50%;
  box-shadow: 0 0 $dds-sizing-2 0 rgb(0 0 0 / 20%);
  padding: $dds-spacing-3 calc(#{$dds-spacing-3} - 2px) $dds-spacing-3 calc(#{$dds-spacing-3} + 2px);

  svg {
    @include dds-box($dds-sizing-4);

    fill: var(--dds-common-video-button-icon-fill);
  }
}
