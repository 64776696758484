@import '~dd-client/site/common/styles/globals';

.WebPushButton-Alert {
  display: inline-flex;
  margin-top: $dds-spacing-1;

  @at-root .StaticPage-Content & {
    a {
      @include dds-link(var(--dds-common-alert-warning-color), var(--dds-common-alert-warning-color), var(--dds-common-alert-warning-color));

      text-decoration: underline;
    }
  }
}
