@import '~dd-client/site/common/styles/globals';

.Apps-Description {
  display: inline-block;
  margin-bottom: $dds-spacing-1;
}

.Apps-LinkImageContainer {
  display: flex;
  flex-wrap: wrap;
}

.Apps-Link {
  height: $dds-sizing-5;
  margin-right: $dds-spacing-1;
  margin-top: $dds-spacing-1;

  &:last-child {
    margin-right: 0;
  }
}

.Apps-Image {
  height: 100%;
}
