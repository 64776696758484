@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.Accordion-Section {
  border-bottom: 1px solid var(--dds-gray-300);
  margin-right: -$dds-spacing-2;
  padding-right: $dds-spacing-2;

  @include media-breakpoint-up(md) {
    margin-right: 0;
    padding-right: 0;
  }
}
