@import '~dd-client/site/common/styles/globals';

.Button {
  &.ButtonIcon {
    padding: 0;
  }

  &.ButtonIcon--Default {
    height: $dds-sizing-5;
    width: $dds-sizing-5;

    img {
      height: $dds-sizing-3;
      width: $dds-sizing-3;
    }
  }

  &.ButtonIcon--Small {
    height: $dds-sizing-4;
    width: $dds-sizing-4;
  }
}
