@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

$progress-bar-desktop-border-radius:  $dds-border-radius-global * 2;

.ProgressBar {
  background-color: var(--dds-common-progress-bar-bg);
  font-family: $dds-font-family-secondary;
  height: $dds-sizing-6;
  margin-left: -$dds-spacing-2;
  margin-right: -$dds-spacing-2;
  position: relative;

  @include media-breakpoint-up(md) {
    margin-left: -$dds-spacing-3;
    margin-right: -$dds-spacing-3;
  }

  @include media-breakpoint-up(lg) {
    border-radius: $progress-bar-desktop-border-radius;
    height: $dds-sizing-8;
    margin-left: 0;
    margin-right: 0;
  }
}

.ProgressBar--Tile {
  border-radius: 0;
  height: $dds-sizing-1;
  margin: 0;

  .ProgressBar-PercentageFill {
    background-size: 5px 5px;
    border-radius: 0;
  }
}

.ProgressBar--SoldOut,
.ProgressBar--Ended {
  background-color: var(--dds-common-progress-bar-sold-out-or-ended-bg);
}

.ProgressBar--Ended {
  .ProgressBar-Text {
    justify-content: center;
  }
}

.ProgressBar-PercentageFill {
  @include dds-pos(absolute, $left: 0);

  background-color: var(--dds-common-progress-bar-filled-bg);
  background-image: linear-gradient(to right, transparent 90%, rgb(0 0 0 / 10%) 10%);
  background-size: $dds-sizing-1 $dds-sizing-1;
  height: 100%;

  @include media-breakpoint-up(lg) {
    border-bottom-left-radius: $progress-bar-desktop-border-radius;
    border-top-left-radius: $progress-bar-desktop-border-radius;
  }
}

.ProgressBar-PercentageFill--Full {
  @include media-breakpoint-up(lg) {
    border-bottom-right-radius: $progress-bar-desktop-border-radius;
    border-top-right-radius: $progress-bar-desktop-border-radius;
  }
}

.ProgressBar-PercentageFill--NearEnd {
  background-color: var(--dds-common-progress-bar-filled-near-end-bg);
}

.ProgressBar-PercentageFill--AlmostEnd {
  background-color: var(--dds-common-progress-bar-filled-almost-end-bg);
}

.ProgressBar-PercentageFill--Reserved {
  background-color: var(--dds-common-progress-bar-filled-reserved-bg);

  & + .ProgressBar-Text {
    .ProgressBar-TextValue {
      padding-left: 0;
    }
  }
}

.ProgressBar-Text {
  @include dds-type-scale(-1);

  align-items: center;
  color: var(--dds-common-progress-bar-color);
  display: flex;
  font-weight: $dds-font-weight-bold;
  height: 100%;
  padding-left: $dds-spacing-1;
  position: absolute;
  text-transform: uppercase;
  width: 100%;

  @include media-breakpoint-up(md) {
    @include dds-type-scale(2);

    padding-left: $dds-spacing-3;
  }

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(5);

    justify-content: center;
    padding-left: 0;
  }
}

.ProgressBar-TextValue {
  @include dds-type-scale(1);

  padding: 0 $dds-spacing-0_5;

  @include media-breakpoint-up(md) {
    @include dds-type-scale(4);

    padding: 0 $dds-spacing-1;
  }

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(8);

    padding: 0 $dds-spacing-1_5;
  }
}

.ProgressBar-Countdown {
  @include dds-type-scale(-1);

  color: var(--dds-common-progress-bar-color);
  height: 100%;
  justify-content: flex-end;
  padding-right: $dds-spacing-1;
  position: absolute;
  width: 100%;

  @include media-breakpoint-up(md) {
    @include dds-type-scale(2);

    padding-right: $dds-spacing-3;
  }

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(5);
  }

  svg {
    @include dds-box($dds-sizing-2);

    fill: var(--dds-common-progress-bar-color);
    margin-right: $dds-spacing-0_5;

    @include media-breakpoint-up(md) {
      @include dds-box($dds-sizing-3);

      margin-right: $dds-spacing-1;
    }

    @include media-breakpoint-up(lg) {
      @include dds-box($dds-sizing-4);
    }
  }
}
