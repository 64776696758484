@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

$progress-bar-border-radius: $dds-border-radius-global * 0.5;
$sticky-bar-desktop-height: $dds-sizing-8;

.StickyBar{
  @at-root .ProductMain--HomePage & {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}

.StickyBar--Active {
  .StickyBar-Bar {
    bottom: 0;
    opacity: 1;

    @include media-breakpoint-up(md) {
      bottom: unset;
      top: 0;
    }
  }
}

.StickyBar-InnerWrapper {
  z-index: $dds-zindex-sticky;
}

.StickyBar-Bar {
  @include dds-pos(fixed, $left: 0, $bottom: calc(-#{$dds-sticky-bar-mobile-height} - env(safe-area-inset-bottom)));

  background-color: var(--dds-white);
  box-shadow: 0 0 $dds-sizing-1 0 rgb(0 0 0 / 15%);
  display: flex;
  height: calc(#{$dds-sticky-bar-mobile-height} + env(safe-area-inset-bottom));
  opacity: 0;
  transition-duration: 0.2s;
  transition-property: bottom, opacity, top;
  transition-timing-function: ease-in-out;
  width: 100%;

  @include media-breakpoint-up(md) {
    bottom: unset;
    height: $sticky-bar-desktop-height;
    top: -$sticky-bar-desktop-height;
  }
}

.StickyBar--IOS {
  .StickyBar-Bar {
    bottom: unset;
    top: var(--vh, 100vh);

    @include media-breakpoint-up(md) {
      top: -$sticky-bar-desktop-height;
    }
  }

  &.StickyBar--Active {
    .StickyBar-Bar {
      top: calc(var(--vh, 100vh) - #{$dds-sticky-bar-mobile-height} - env(safe-area-inset-bottom));

      @include media-breakpoint-up(md) {
        top: 0;
      }
    }
  }
}

.StickyBar-Content {
  align-items: flex-start;
  display: flex;
  height: 100%;
  justify-content: space-between;
  padding-bottom: $dds-spacing-1;
  padding-top: $dds-spacing-1_5;
  position: relative;

  @include media-breakpoint-up(md) {
    align-items: center;
    padding-top: 0;
  }
}

.StickyBar-Title {
  @include dds-type-scale(1);

  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  display: none; /* stylelint-disable-line order/properties-alphabetical-order */
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  letter-spacing: rem(-0.54px);
  overflow: hidden;
  padding-right: $dds-spacing-1_5;

  @include media-breakpoint-up(md) {
    display: -webkit-box;
  }
}

.StickyBar-PriceAndBuyButton {
  align-items: center;
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;

  @include media-breakpoint-up(md) {
    flex-basis: auto;
    justify-content: normal;
  }
}

.StickyBar-Price {
  .Price-Price {
    @include dds-type-scale(3);

    letter-spacing: rem(-0.68px);

    @include media-breakpoint-up(md) {
      @include dds-type-scale(1);

      letter-spacing: rem(-0.54px);
    }
  }

  .Price-Discount {
    @include dds-type-scale(-1);

    letter-spacing: rem(-0.42px);
    line-height: $dds-line-height-list;
    margin-left: $dds-spacing-0_5;

    @include media-breakpoint-up(md) {
      @include dds-type-scale(xsmall);

      letter-spacing: rem(-0.38px);
      line-height: $dds-line-height-condensed;
      margin-left: $dds-spacing-0_5;
    }
  }

  .Price-DiscountPercentage {
    @include dds-type-scale(-2);

    line-height: $dds-line-height-list;
    margin-left: $dds-spacing-0_25;

    @include media-breakpoint-up(md) {
      @include dds-type-scale(-4);

      line-height: $dds-line-height-condensed;
      margin-left: $dds-spacing-0_25;
    }
  }
}

.StickyBar-BuyButton {
  flex-grow: 1;
  margin: 0 0 0 $dds-spacing-1_5;

  .BuyButton-Button {
    @include dds-type-scale(small);

    font-weight: $dds-font-weight-bold;
    line-height: $dds-line-height-condensed;
    padding: $dds-spacing-2 $dds-spacing-1_5;

    @include media-breakpoint-up(md) {
      border-radius: $dds-border-radius-global;
      padding: $dds-spacing-0_5 $dds-spacing-2;
    }
  }

  svg {
    @include dds-box($dds-sizing-2);

    margin-right: $dds-spacing-0_5;

    @include media-breakpoint-up(md) {
      @include dds-box($dds-sizing-3);

      margin-right: $dds-spacing-1;
    }
  }
}

.StickyBar-ProgressBar {
  @include dds-pos(absolute, $top: 0);

  height: $dds-sizing-0_5;
  margin: 0 #{-$dds-spacing-2};
  width: calc(100% + #{$dds-spacing-4});

  .ProgressBar-PercentageFill {
    @include media-breakpoint-up(md) {
      border-bottom-left-radius: $progress-bar-border-radius;
      border-top-left-radius: $progress-bar-border-radius;
    }
  }

  .ProgressBar-PercentageFill--Full {
    @include media-breakpoint-up(md) {
      border-bottom-right-radius: $progress-bar-border-radius;
      border-top-right-radius: $progress-bar-border-radius;
    }
  }

  @include media-breakpoint-up(md) {
    border-radius: $progress-bar-border-radius;
    bottom: 0;
    height: $dds-sizing-1;
    margin: 0;
    top: unset;
    width: 100%;
  }
}
