@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.PastDealsPanel {
  padding: $dds-spacing-2 0 $dds-spacing-6;

  @include media-breakpoint-up(lg) {
    padding: $dds-spacing-6 0 $dds-spacing-12;
  }
}

.PastDealsPanel-Loader {
  margin: auto;
}

.PastDealsPanel-DealList {
  @include dds-list-unstyled(false);

  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-up(md) {
    margin: 0 #{-$dds-spacing-1_5};
  }

  @include media-breakpoint-up(lg) {
    margin: 0 #{-$dds-spacing-2};
  }
}
