@import '~dd-client/site/common/styles/globals';

.ContentNavigation {
  display: flex;
}

.ContentNavigation-List {
  @include dds-list-unstyled(false);

  flex: 0 0 35%;
  margin-right: $dds-spacing-4;
}

.ContentNavigation-Content {
  padding-top: $dds-spacing-2;
}
