@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

$comments-modal-max-width: 640px;
$comments-box-and-button-border-radius: $dds-border-radius-global * 2;
$comments-box-and-button-shadow-hover: 0 0 $dds-sizing-2 0 rgb(0 0 0 / 10%);
$comments-box-and-button-transition-duration: 0.2s;
$comments-box-and-button-transition-function: ease-out;

.Comments {
  background-color: var(--dds-deal-deal-page-comments-bg);
  padding-bottom: $dds-spacing-3;
  padding-top: $dds-spacing-3;

  @include media-breakpoint-up(md) {
    padding-bottom: $dds-spacing-5;
    padding-top: $dds-spacing-5;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: $dds-spacing-8;
    padding-top: $dds-spacing-6;
  }
}

.Comments-Title {
  align-items: center;
  column-gap: $dds-spacing-2;
  display: flex;
  flex-wrap: wrap;
  line-height: $dds-spacing-4;
  margin-bottom: $dds-spacing-2;

  @include media-breakpoint-up(lg) {
    column-gap: $dds-spacing-3;
  }
}

.Comments-Title--NoComment {
  color: var(--dds-gray-400);
  font-weight: $dds-font-weight-extrabold;
}

.Comments-MessageIcon {
  @include dds-box($dds-sizing-4);

  fill: var(--dds-gray-400);
}

.Comments-BoxWrapper {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    column-gap: $dds-spacing-3;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @include media-breakpoint-up(lg) {
    column-gap: $dds-spacing-4;
    flex-wrap: nowrap;
  }
}

.Comments-CommentBox {
  background-color: var(--dds-deal-deal-page-comments-box-bg);
  border-radius: $comments-box-and-button-border-radius;
  cursor: pointer;
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  margin-bottom: $dds-spacing-2;
  padding: $dds-spacing-2;
  transition-duration: $comments-box-and-button-transition-duration;
  transition-property: box-shadow;
  transition-timing-function: $comments-box-and-button-transition-function;

  .CommentBox-Content {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    margin-bottom: auto;
    overflow: hidden;
  }

  &:hover {
    box-shadow: $comments-box-and-button-shadow-hover;
  }

  @include media-breakpoint-up(md) {
    flex: 0 0 calc(50% - #{$dds-spacing-1_5});
  }

  @include media-breakpoint-up(lg) {
    flex: 0 1 41%;
    margin-bottom: 0;
  }
}

.Comments-ButtonWrapper {
  display: flex;
  flex: 0 0 100%;

  @include media-breakpoint-up(lg) {
    flex: 0 0 15%;
  }
}

.Comments-SeeAllButton {
  @include dds-type-scale(-1);
  @include dds-button(
    $bg: var(--dds-deal-deal-page-comments-see-all-button-bg),
    $color: var(--dds-deal-deal-page-comments-see-all-button-color),
    $color-hover: var(--dds-deal-deal-page-comments-see-all-button-color-hover),
    $color-active: var(--dds-deal-deal-page-comments-see-all-button-color-active),
  );

  border-radius: $comments-box-and-button-border-radius;
  box-shadow: none;
  flex-grow: 1;
  padding: $dds-spacing-2;
  transition-duration: $comments-box-and-button-transition-duration;
  transition-property: color, box-shadow;
  transition-timing-function: $comments-box-and-button-transition-function;
  white-space: nowrap;

  svg {
    margin-right: $dds-spacing-1;
    transition-duration: $comments-box-and-button-transition-duration;
    transition-property: fill;
    transition-timing-function: $comments-box-and-button-transition-function;
  }

  &:hover {
    box-shadow: $comments-box-and-button-shadow-hover;

    svg {
      fill: var(--dds-deal-deal-page-comments-see-all-button-color-hover);
    }
  }

  &:active {
    transition: unset;

    svg {
      fill: var(--dds-deal-deal-page-comments-see-all-button-color-active);
      transition: unset;
    }
  }

  @include media-breakpoint-up(lg) {
    flex-direction: column;

    svg {
      margin-bottom: $dds-spacing-1;
      margin-right: 0;
    }
  }
}

.Comments-Modal {
  border-radius: 0;
  padding: 0 $dds-spacing-2 $dds-spacing-2;

  @include media-breakpoint-up(md) {
    max-width: $comments-modal-max-width;
    padding:  0 $dds-spacing-3 $dds-spacing-2;
  }
}

.Comments-Alert {
  background-color: transparent;
  margin-top: $dds-spacing-4;
  padding: 0;

  .Alert-Content {
    display: flex;
  }
}

.Comments-RetryButton {
  color: var(--dds-common-alert-warning-color);
  font-family: $dds-font-family-base;
  font-weight: $dds-font-weight-semibold;
  margin-left: $dds-spacing-1_5;
  text-decoration: underline;
  text-transform: none;
}
