@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.Accordion-SectionContent {
  display: none;
  padding-bottom: $dds-spacing-1_5;
}

.Accordion-SectionContent--Open {
  display: block;
}
