@import '~dd-client/site/common/styles/globals';

.ItemSkeleton {
  min-height: $dds-sizing-50;
}

.ItemSkeleton-Spinner {
  svg {
    fill: var(--dds-deal-deal-page-news-bg);
  }
}
