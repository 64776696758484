@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.Accessories {
  padding-bottom: $dds-spacing-3;

  @include media-breakpoint-up(md) {
    padding-bottom: $dds-spacing-6;
    padding-top: $dds-spacing-3;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: $dds-spacing-8;
    padding-top: $dds-spacing-6;
  }
}

.Accessories-Accordion {
  display: block;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.Accessories-ContentNavigation {
  display: none;

  .ContentNavigation-Content {
    padding-top: 0;
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    display: flex;
  }
}
