@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.DeliveryInfo {
  color: var(--dds-gray-600);
  display: flex;
  line-height: $dds-line-height-base;
  margin-bottom: $dds-spacing-3;

  svg {
    @include dds-box($dds-sizing-3);

    fill: var(--dds-gray-500);
    flex: 0 0 $dds-sizing-3;
    margin-right: $dds-sizing-1;
  }

  @at-root .ProductMain--HomePage & {
    display: none;

    @include media-breakpoint-up(md) {
      display: flex;
    }
  }

  @include media-breakpoint-up(md) {
    @include dds-type-scale(-1);

    line-height: $dds-line-height-base;
    margin-bottom: $dds-spacing-3;
  }

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(0);

    line-height: $dds-line-height-base;
    margin-bottom: $dds-spacing-3;
  }
}
