@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.UniqueSellingPoints {
  display: flex;
  flex-direction: column;
  padding-bottom: $dds-spacing-6;
  padding-top: $dds-spacing-6;

  @include media-breakpoint-up(md) {
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: $dds-spacing-8;
    padding-top: $dds-spacing-8;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: $dds-spacing-6;
    padding-top: 0;
  }
}

.UniqueSellingPoints-Point {
  @include dds-type-scale(-1);
  @include dds-link(var(--dds-gray-600));

  align-items: center;
  display: flex;
  flex-direction: column;
  line-height: $dds-line-height-list;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: $dds-spacing-4;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  svg {
    border-radius: $dds-border-radius-global * 2;
    box-shadow: inset 0 0 0 2px var(--dds-gray-200);
    margin-bottom: $dds-spacing-1;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      margin-right: $dds-spacing-2;
    }
  }

  [class$='ColoredSvgPath'] {
    fill: var(--dds-primary);
  }

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(0);

    flex-direction: row;
    line-height: $dds-line-height-list;
    text-align: left;
  }
}
