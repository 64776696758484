@import '~dd-client/site/common/styles/globals';

.Info {
  @include dds-type-scale(-2);

  color: var(--dds-gray-500);

  & + .Error {
    margin-top: -$dds-spacing-0_75;
  }
}
