@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.Accordion-SectionTitle {
  @include dds-type-scale(0);

  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  justify-content: space-between;
  letter-spacing: rem(-0.48px);
  padding: $dds-spacing-1_5 0;

  svg {
    @include dds-box($dds-sizing-3);

    fill: var(--dds-gray-400);
  }
}

.Accordion-SectionTitle--Open {
  color: var(--dds-primary);

  svg {
    fill: var(--dds-primary);
    transform: rotate(90deg);
  }
}
