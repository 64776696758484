@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.CategoryMenu {
  background-color: var(--dds-app-header-category-menu-bg);
  display: none;
  padding: $dds-spacing-1 0;
  position: relative;

  &::after,
  &::before {
    content: '';
    height: 100%;
    width: $dds-sizing-3;
    z-index: 1;
  }

  &::after {
    @include dds-pos(absolute, $top: 0, $right: 0);

    background: linear-gradient(270deg, var(--dds-app-header-category-menu-bg) 20%, var(--dds-app-header-category-menu-side-shadows-bg) 100%);
  }

  &::before {
    @include dds-pos(absolute, $top: 0, $left: 0);

    background: linear-gradient(90deg, var(--dds-app-header-category-menu-bg) 20%, var(--dds-app-header-category-menu-side-shadows-bg) 100%);
  }

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.CategoryMenu-Content {
  display: flex;
  justify-content: space-between;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow-x: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.CategoryMenu-MobileHomePageTitle {
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  letter-spacing: -0.48px;
  margin-bottom: $dds-spacing-2;
  text-align: center;
  text-transform: uppercase;
}

.CategoryMenu--Mobile {
  display: block;
  padding-top: $dds-spacing-4;

  .CategoryMenu-Content {
    flex-direction: column;
  }

  &::after,
  &::before {
    display: none;
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.CategoryMenu--MobileHomePage {
  padding-bottom: $dds-spacing-7;
}

.CategoryMenu--MobileDealPage {
  padding-bottom: $dds-spacing-6;

  .CategoryMenu-Content {
    overflow-x: unset; // needed to work the slider correctly
  }
}

.CategoryMenu-MobileDealPageTitle {
  margin-bottom: $dds-spacing-2;
}

.CategoryMenu-Slider {
  .Swiper-Slide {
    @include dds-slide-margin-right;

    width: 90%;
  }
}
