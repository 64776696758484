@import '~dd-client/site/common/styles/globals';
@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';

.Header-MainWrapper {
  transform: translateY(0);
  transition: transform 0.2s ease-in-out;
}

.Header-MainWrapper--Mobile {
  // height of HeaderMain and Back button
  transform: translateY(-$dds-spacing-9);
}

.Header-Sticky--Active {
  .Header-MainWrapper--Mobile {
    transform: translateY(0);
  }
}

.Header-Main {
  background: var(--dds-app-header-bg);
}

.Header-MainContent {
  @include dds-type-scale(-2);

  align-items: center;
  display: flex;
  height: $dds-sizing-5;
  justify-content: space-between;
  overflow: hidden;
  width: 100%;

  @include media-breakpoint-up(md) {
    height: $dds-sizing-8;
  }
}

.Header-Logo {
  height: $dds-sizing-3;

  @include media-breakpoint-up(md) {
    height: $dds-sizing-4;
  }
}

.Header-ThemeLogo {
  max-height: $dds-sizing-5;

  @include media-breakpoint-up(md) {
    max-height: $dds-sizing-8;
  }
}

.Header-Offer {
  align-items: center;
  display: flex;
}

.Header-BrackLogo {
  display: none;
  max-height: $dds-sizing-2_5;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.Header-OfferText {
  @include dds-type-scale(-2);

  color: var(--dds-app-header-offer-text-color);
  display: none;
  font-weight: $dds-font-weight-semibold;
  padding-right: $dds-sizing-1;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.Header-Hamburger {
  @include dds-type-scale(-1);

  align-items: center;
  color: var(--dds-white);
  cursor: pointer;
  display: flex;
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-extrabold;
  line-height: $dds-line-height-list;
  margin-left: $dds-sizing-3;
  text-transform: uppercase;

  svg {
    margin-left: $dds-spacing-0_5;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.Header-StickyInnerWrapper {
  z-index: $dds-zindex-sticky;
}

.Header-BackButtonWrapper {
  background-color: var(--dds-gray-100);
  box-shadow: 0 0 $dds-sizing-1 0 rgb(0 0 0 / 15%);
  padding: $dds-spacing-1 0;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.Header-BackButton {
  @include dds-type-scale(-2);

  letter-spacing: rem(-0.36px);
  line-height: $dds-line-height-condensed;

  &.Button {
    svg {
      margin-right: 0;
    }
  }
}
