@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.Socials {
  @include dds-list-unstyled;

  display: flex;
  flex: 1 1 100%;
  justify-content: space-around;
  margin-bottom: $dds-spacing-6;
  order: 1;

  li {
    margin-right: 0;

    @include media-breakpoint-up(md) {
      margin-right: $dds-spacing-6;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  svg {
    @include dds-box($dds-sizing-4);

    fill: var(--dds-gray-900);
  }

  @include media-breakpoint-up(md) {
    justify-content: center;
    margin-bottom: $dds-spacing-8;
    order: 0;
  }

  @include media-breakpoint-up(lg) {
    justify-content: flex-start;
    margin-bottom: $dds-spacing-6;
  }
}
