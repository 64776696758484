@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.NewsItem {
  @include dds-link;

  background-color: var(--dds-deal-deal-page-news-item-bg);
  border-radius: $dds-border-radius-global;
  display: block;
  height: 100%;
  overflow: hidden;

  &:hover {
    text-decoration: none;
  }

  @include media-breakpoint-up(md) {
    border-radius: $dds-border-radius-global * 2;
  }
}

.NewsItem-TransparentBorder {
  border: 1px solid var(--dds-deal-deal-page-news-item-border-color);
  border-radius: $dds-border-radius-global;
  height: 100%;
  position: absolute;
  width: 100%;

  @include media-breakpoint-up(md) {
    border-radius: $dds-border-radius-global * 2;
  }
}

.NewsItem-Image {
  max-width: 100%;
}

.NewsItem-BottomWrapper {
  border-top: 1px solid var(--dds-gray-200);
  padding: $dds-spacing-1_5;
  text-align: left;

  @include media-breakpoint-up(md) {
    padding: $dds-spacing-2;
  }

  @include media-breakpoint-up(lg) {
    padding: $dds-spacing-3;
  }
}

.NewsItem-Title {
  @include dds-font-size(body);

  margin-bottom: $dds-spacing-0_5;

  @include media-breakpoint-up(md) {
    @include dds-type-scale(h2);

    margin-bottom: $dds-spacing-0_5;
  }
}

.NewsItem-Headline {
  p {
    @include dds-font-size(body-sm);

    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      @include dds-font-size(body);
    }

    @include media-breakpoint-up(lg) {
      @include dds-font-size(body-lg);
    }
  }
}
