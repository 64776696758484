@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.PastDealTile {
  background-color: var(--dds-white);
  border-radius: $dds-border-radius-global * 2;
  flex-basis: 100%;
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  margin-bottom: $dds-spacing-2;
  padding: $dds-spacing-2;

  @include media-breakpoint-up(md) {
    flex-basis: calc(50% - #{$dds-sizing-3});
    margin: 0 $dds-spacing-1_5 $dds-spacing-3;
  }

  @include media-breakpoint-up(lg) {
    flex-basis: calc(33.33333% - #{$dds-sizing-4});
    margin: 0 $dds-spacing-2 $dds-spacing-4;
  }
}

.PastDealTile-Header {
  @include dds-type-scale(-3);

  display: flex;
  justify-content: space-between;
  letter-spacing: rem(-0.34px);
  margin-bottom: $dds-spacing-1_5;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(-1);

    letter-spacing: rem(-0.43px);
    margin-bottom: $dds-spacing-1_5;
  }
}

.PastDealTile-Category {
  color: var(--dds-gray-500);
}

.PastDealTile-ImageLink {
  display: block;
  margin: 0 #{-$dds-spacing-2} $dds-spacing-1_5;
  position: relative;
  text-align: center;

  &::after {
    @include dds-pos(absolute, $top: 0, $left: 0);

    box-shadow: inset 0 -12px 32px -32px rgb(0 0 0 / 40%);
    content: '';
    height: 100%;
    width: 100%;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: $dds-spacing-2;
  }
}

.PastDealTile-Image {
  max-width: 100%;
}

.PastDealTile-Title {
  @include dds-type-scale(1);
  @include dds-link(var(--dds-primary));

  display: inline-block;
  letter-spacing: rem(-0.54px);
  margin-bottom: $dds-spacing-0_5;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(3);

    letter-spacing: rem(-0.68px);
    margin-bottom: $dds-spacing-0_5;
  }
}

.PastDealTile-Subtitle {
  @include dds-type-scale(-1);

  color: var(--dds-gray-500);
  letter-spacing: rem(-0.43px);
  line-height: $dds-line-height-list;
  margin-bottom: $dds-spacing-2;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(1);

    letter-spacing: rem(-0.54px);
    margin-bottom: $dds-spacing-2;
  }
}

.PastDealTile-BrackButtonComments {
  align-items: center;
  display: flex;
}

.PastDealTile-BrackButton {
  @include dds-buy-button-bg-color;

  svg {
    height: $dds-sizing-2_25;
    margin: 0 $dds-spacing-0_5;
    width: $dds-sizing-6;
  }
}

.PastDealTile-Comments {
  @include dds-type-scale(-2);
  @include dds-link(var(--dds-gray-500));

  align-items: center;
  display: flex;
  letter-spacing: rem(-0.38px);
  margin-left: auto;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(-1);

    letter-spacing: rem(-0.43px);
    margin-left: auto;
  }
}

.PastDealTile-CommentsIcon {
  @include dds-box($dds-sizing-2);

  fill: var(--dds-gray-400);
  margin-right: $dds-sizing-0_5;

  @include media-breakpoint-up(lg) {
    @include dds-box($dds-sizing-3);
  }
}
