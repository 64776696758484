@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

$redButtonColor: #f23129;

.BuyButton {
  margin-bottom: $dds-spacing-1;

  @at-root .ProductMain--HomePage & {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}

.BuyButton-Label {
  white-space: nowrap;
}

.BuyButton-Button {
  @include dds-type-scale(1);

  border-radius: $dds-border-radius-global * 2;
  font-weight: $dds-font-weight-extrabold;
  padding: $dds-spacing-1_5 $dds-spacing-2;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(3);

    svg {
      @include dds-box($dds-sizing-5);

      margin-right: $dds-spacing-2;
    }
  }
}

.BuyButton-Button--Default {
  @include dds-buy-button-bg-color;
}


.BuyButton-Button--PastDeal {
  background-color: $redButtonColor;

  &:hover {
    background-color: lighten($redButtonColor, 5);
  }

  &:active {
    background-color: darken($redButtonColor, 5);
  }
}
