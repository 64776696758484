@import '~dd-client/site/common/styles/globals';

.TextArea {
  position: relative;
}

.TextArea-Input {
  @include dds-form-input;

  vertical-align: bottom;
}

.TextArea-AutoGrowWrapper {
  display: grid;

  &::after {
    @include dds-type-scale(body);

    content: attr(data-replicated-value) ' ';
    grid-area: 1 / 1 / 2 / 2;
    max-height: calc(#{$dds-line-height-base} * 10 + #{$dds-spacing-1_5});
    padding: $dds-spacing-0_75 $dds-spacing-1_5;
    visibility: hidden;
    white-space: pre-wrap;
  }

  .TextArea-Input {
    grid-area: 1 / 1 / 2 / 2;
  }
}

.TextArea--Error {
  .TextArea-Input {
    @include dds-form-input-error;
  }
}

.TextArea--Disabled {
  opacity: 0.5;
}
