@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

$max-content-width: 686px;

.BanPage-Container {
  text-align: center;
}

.BanPage-Video {
  display: block;
  margin: auto;
  max-height: $dds-sizing-30;
  max-width: 100%;

  @include media-breakpoint-up(lg) {
    max-height: 100%;
  }
}

.BanPage-Content {
  background-color: var(--dds-gray-100);
  padding: $dds-spacing-8 $dds-spacing-2;
}

.BanPage-DescWrapper {
  margin: 0 auto;
  max-width: $max-content-width;
  text-align: left;
}

.BanPage-DescMainInfoWrapper {
  text-align: center;
}

.BanPage-DescMainInfoHeader {
  @include dds-type-scale(6);

  letter-spacing: rem(-0.97px);
  margin-bottom: $dds-spacing-3;

  @include media-breakpoint-up(md) {
    line-height: $dds-line-height-list;
  }
}

.BanPage-DescMainInfoParagraph {
  margin-bottom: $dds-spacing-8;
}

.BanPage-DescMainInfoIpAddress {
  font-weight: $dds-font-weight-bold;
}

.BanPage-DescHeader {
  margin-bottom: $dds-spacing-2;
}

.BanPage-DescParagraph {
  margin-bottom: 0;
}

.BanPage-List {
  margin-bottom: $dds-spacing-3;
}
