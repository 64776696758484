@import '~dd-client/site/common/styles/globals';

.Label {
  @include dds-pos(absolute, $bottom: calc(100% - #{$dds-spacing-3_75}), $left: $dds-spacing-1_5);
  @include dds-type-scale(body);

  color: var(--dds-gray-400);
  font-weight: $dds-font-weight-semibold;
  pointer-events: none;

  & + input {
    @include dds-input-placeholder($opacity: 0);
  }

  & + div {
    textarea {
      @include dds-input-placeholder($opacity: 0);
    }
  }
}

.Label--Top {
  @include dds-pos(absolute, $bottom: 100%, $left: 0);
  @include dds-type-scale(-2);

  color: var(--dds-gray-600);
  font-weight: $dds-font-weight-regular;
  pointer-events: auto;

  & + input {
    @include dds-input-placeholder($opacity: 1);
  }

  & + div {
    textarea {
      @include dds-input-placeholder($opacity: 1);
    }
  }
}
