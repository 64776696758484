@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

$dds-news-image-lg-max-width: 450px;
$dds-news-item-border-radius: $dds-border-radius-global * 2;

.NewsPage-Item {
  flex-basis: 100%;
  margin-bottom: $dds-spacing-2;

  @include media-breakpoint-only(md) {
    flex-basis: calc(50% - #{$dds-sizing-3});
    margin: 0 $dds-spacing-1_5 $dds-spacing-3;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: $dds-spacing-3;
  }
}

.NewsPage-ItemLink {
  background-color: var(--dds-white);
  border-radius: $dds-news-item-border-radius;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-decoration: none;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
}

.NewsPage-ItemTransparentBorder {
  border: 1px solid rgb(255 255 255 / 50%);
  border-radius: $dds-news-item-border-radius;
  height: 100%;
  position: absolute;
  width: 100%;
}

.NewsPage-ItemImage {
  align-self: center;
  max-width: 100%;

  @include media-breakpoint-up(lg) {
    align-self: flex-start;
    max-width: $dds-news-image-lg-max-width;
  }
}

.NewsPage-ItemDescriptionWrapper {
  padding: $dds-sizing-2;

  @include media-breakpoint-up(lg) {
    padding: $dds-sizing-3 $dds-sizing-4;
  }
}

.NewsPage-ItemTitle {
  @include dds-type-scale(1);

  color: var(--dds-primary);
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  letter-spacing: rem(-0.54px);
  margin-bottom: $dds-spacing-0_5;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(3);

    letter-spacing: rem(-0.68px);
    margin-bottom: $dds-spacing-1;
  }
}

.NewsPage-ItemHeadline {
  margin-bottom: $dds-spacing-0_5;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(1);

    margin-bottom: $dds-spacing-1;
  }
}

.NewsPage-ItemLeadText {
  @include dds-content-text-links;

  color: var(--dds-gray-600);

  &,
  p {
    @include dds-type-scale(-1);

    line-height: $dds-line-height-list;
  }

  @include media-breakpoint-up(lg) {
    &,
    p {
      @include dds-type-scale(0);

      line-height: $dds-line-height-base;
    }
  }
}
