@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

$static-page-content-max-width: 930px;
$static-page-accordion-content-bg: rgb(35 180 12 / 10%);
$static-page-accordion-open-header-color: var(--dds-white);
$static-page-table-border-color: var(--dds-gray-200);
$static-page-hr-border-color: var(--dds-gray-300);

.StaticPage {
  padding-bottom: $dds-spacing-6;
}

.StaticPage-HeaderImageWrapper {
  text-align: center;
}

.StaticPage-HeaderImage {
  max-width: 100%;
}

.StaticPage-Loader {
  margin: $dds-spacing-6 auto 0;
}

.StaticPage-Content {
  @include dds-content-text-links;
  @include dds-content-img;

  margin: auto;
  max-width: $static-page-content-max-width;
  padding-top: $dds-spacing-6;

  h1,
  h2,
  h3,
  h4 {
    margin-bottom: $dds-spacing-1;
  }

  h1 {
    & + p {
      @include dds-type-scale(h2-m, 1);

      @include media-breakpoint-up(md) {
        @include dds-type-scale(h2, 1);
      }
    }
  }

  .TableResponsive {
    overflow-x: auto;
    width: 100%;
  }

  table {
    border-radius: $dds-border-radius-global;
    border-style: hidden;
    box-shadow: inset 0 0 0 1px $static-page-table-border-color;
    margin-bottom: $dds-spacing-3;

    td {
      border: 1px solid $static-page-table-border-color;
      padding: $dds-spacing-1_5 $dds-spacing-2;
      vertical-align: top;
    }
  }

  hr {
    border-color: $static-page-hr-border-color;
    border-style: solid;
    border-width: 1px 0 0;
    margin-bottom: $dds-spacing-3;
    margin-top: 0;
    opacity: 1;
  }

  .Accordion {
    @include dds-list-unstyled(false);

    li {
      margin-bottom: $dds-spacing-1;
      position: relative;
    }

    h3 {
      @include dds-type-scale(1);

      align-items: center;
      background-color: var(--dds-gray-100);
      border-radius: $dds-border-radius-global;
      display: flex;
      font-family: $dds-font-family-base;
      font-weight: $dds-font-weight-regular;
      padding: $dds-spacing-2;

      &::before {
        @include dds-type-scale(3);
        @include dds-box($dds-sizing-3);

        color: var(--dds-primary);
        content: '+';
        font-weight: $dds-font-weight-semibold;
        margin-right: $dds-spacing-1;
        text-align: center;
      }
    }

    p {
      @include dds-type-scale(1);

      background-color: $static-page-accordion-content-bg;
      border-bottom-left-radius: $dds-border-radius-global;
      border-bottom-right-radius: $dds-border-radius-global;
      height: 0;
      margin: 0;
      opacity: 0;
      overflow: hidden;
      padding: 0 $dds-spacing-2;
      position: relative;
      transition-duration: 0.25s;
      transition-property: height, opacity, padding;
      transition-timing-function: ease-in-out;
      z-index: 2;
    }

    input {
      &[type='checkbox'] {
        @include dds-pos(absolute, $top: 0);

        cursor: pointer;
        height: 100%;
        opacity: 0;
        width: 100%;
        z-index: 1;

        &:checked {
          & ~ h3 {
            background-color: var(--dds-primary);
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            color: $static-page-accordion-open-header-color;

            &::before {
              color: $static-page-accordion-open-header-color;
              content: '–';
              line-height: $dds-line-height-list + 1px;
            }
          }

          & ~ p {
            height: auto;
            opacity: 1;
            padding: $dds-spacing-2;
          }
        }
      }
    }
  }
}
