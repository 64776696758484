@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.SelectedVariant-Image {
  margin-bottom: $dds-spacing-3;
  max-width: 100%;
}

.SelectedVariant-Name {
  @include dds-type-scale(1);

  letter-spacing: rem(-0.54px);
  margin-bottom: $dds-spacing-0_5;
}

.SelectedVariant-Price {
  margin-bottom: $dds-spacing-2;
}
