@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

$modal-max-width: map-get($container-max-widths, 'xl');

.Media {
  @include dds-list-unstyled(false);

  display: flex;
  flex-wrap: wrap;
  margin: 0 #{-$dds-spacing-2} #{-$dds-spacing-2} 0;
  padding: 0 0 $dds-spacing-5;

  @include media-breakpoint-up(md) {
    margin: 0 #{-$dds-spacing-3} #{-$dds-spacing-3} 0;
    padding: 0 0 $dds-spacing-6;
  }

  @include media-breakpoint-up(lg) {
    margin: 0 #{-$dds-spacing-4} #{-$dds-spacing-4} 0;
  }
}

.Media-Item {
  aspect-ratio: 1/1;
  flex: 0 0 calc(50% - #{$dds-spacing-2});
  margin: 0 $dds-spacing-2 $dds-spacing-2 0;

  @include media-breakpoint-up(md) {
    flex-basis: calc(33.333% - #{$dds-spacing-3});
    margin: 0 $dds-spacing-3 $dds-spacing-3 0;
  }

  @include media-breakpoint-up(lg) {
    flex-basis: calc(16.667% - #{$dds-spacing-4});
    margin: 0 $dds-spacing-4 $dds-spacing-4 0;
  }
}

.Media-Link {
  align-items: center;
  border: 1px solid var(--dds-deal-deal-page-product-details-description-media-item-border-color);
  border-radius: $dds-border-radius-global;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;

  &:hover {
    border-color: var(--dds-deal-deal-page-product-details-description-media-item-border-color-hover);
  }
}

.Media-Thumbnail {
  max-height: 100%;
  max-width: 100%;
}

.Media-Play,
.Media-More {
  @include dds-box($dds-sizing-6);
  @include dds-pos(absolute, 50%, 50%);

  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  transform: translate(50%, -50%);
}

.Media-Play {
  background-color: var(--dds-deal-deal-page-product-details-description-media-item-play-bg);

  svg {
    @include dds-box($dds-sizing-3);

    fill: var(--dds-text-body);
    margin-left: $dds-sizing-0_5; // fine-tuning
  }
}

.Media-MoreOverlay {
  @include dds-pos(absolute, 0, 0, 0, 0);

  background-color: var(--dds-deal-deal-page-product-details-description-media-item-more-overlay-bg);
  height: 100%;
  width: 100%;
}

.Media-More {
  @include dds-type-scale(h3);

  background-color: var(--dds-deal-deal-page-product-details-description-media-item-more-bg);
  color: var(--dds-text-body);
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;

  span {
    margin-left: -2px; // fine-tuning
  }
}

.Media-ModalGallery {
  @include media-breakpoint-up(md) {
    @include dds-pos(fixed, $top: 0, $left: 0);

    background-color: var(--dds-common-modal-bg);
    border-radius: 0;
    height: 100%;
    max-height: none;
    max-width: none;
    padding: $dds-spacing-4 $dds-spacing-1;
    transform: none;
    width: 100%;
    z-index: $dds-zindex-modal;
  }

  @include media-breakpoint-up(lg) {
    @include dds-pos(fixed, $top: 50%, $left: 50%);

    border-radius: $dds-border-radius-global * 2;
    box-shadow: $dds-box-shadow;
    height: calc(100% - #{$dds-spacing-12});
    max-height: calc(100% - #{$dds-spacing-12});
    max-width: $modal-max-width;
    padding: $dds-spacing-4;
    transform: translate(-50%, -50%);
    width: calc(100% - #{$dds-spacing-12});
  }
}
