@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

$newsletter-width: 420px;

.FooterNewsletter {
  margin-bottom: $dds-spacing-6;
  max-width: 100%;
  width: $newsletter-width;

  @include media-breakpoint-up(md) {
    margin-bottom: $dds-spacing-8;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: $dds-spacing-6;
  }
}

.FooterNewsletter-Title {
  margin-bottom: $dds-spacing-2;
}
