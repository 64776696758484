@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.Footer-Bottom {
  @include dds-type-scale(xsmall, $line-height: $dds-line-height-condensed);

  color: var(--dds-gray-500);
  display: flex;
  flex-direction: column;
  text-align: center;

  @include media-breakpoint-up(md) {
    @include dds-type-scale(-1, $line-height: $dds-line-height-list);

    text-align: left;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.Footer-Bottom--BottomSpace {
  margin-bottom: calc(#{$dds-sticky-bar-mobile-height} + env(safe-area-inset-bottom));
}

.Footer-BottomLinkList {
  @include dds-list-unstyled;

  margin-bottom: $dds-spacing-1_5;

  & > li {
    margin-right: $dds-spacing-1_5;

    &:first-child {
      &::before {
        display: none;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    &::before {
      content: '·';
      display: inline-block;
      text-align: left;
      width: $dds-spacing-1_5;
    }
  }

  @include media-breakpoint-up(md) {
    margin-bottom: $dds-spacing-2;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }
}

.Footer-Currency {
  margin-bottom: $dds-spacing-1_5;

  @include media-breakpoint-up(md) {
    margin-bottom: $dds-spacing-2;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }
}


.Footer-BottomLink {
  @include dds-link(var(--dds-gray-500));
}
