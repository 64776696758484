@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

$selected-side-tablet-desktop-width:  335px;

.Variants-Wrapper {
  display: flex;
  flex-direction: column;
  font-weight: $dds-font-weight-bold;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
}

.Variants-Label {
  @include dds-type-scale(1);

  font-family: $dds-font-family-secondary;
  letter-spacing: rem(-0.54px);
  margin-bottom: $dds-spacing-2;

  @include media-breakpoint-up(md) {
    @include dds-type-scale(3);

    letter-spacing: rem(-0.68px);
    margin-bottom: $dds-spacing-3;
  }
}

.Variants-TilesSide {
  border-bottom: 1px solid var(--dds-gray-200);
  flex: 1 1 auto;
  padding: $dds-spacing-2;

  @include media-breakpoint-up(md) {
    padding: $dds-spacing-4 $dds-spacing-1 $dds-spacing-1 $dds-spacing-4;
  }

  @include media-breakpoint-up(lg) {
    border-bottom: 0;
    border-right: 1px solid var(--dds-gray-200);
    padding-bottom: $dds-spacing-4;
  }
}

.Variants-GroupName {
  @include dds-type-scale(2, 0.75);

  color: var(--dds-gray-500);
  font-family: $dds-font-family-secondary;
  letter-spacing: rem(-0.61px);
  margin-bottom: $dds-spacing-1;

  @include media-breakpoint-up(md) {
    margin-bottom: $dds-spacing-2;
  }
}

.Variants-Group {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  &:not(:last-child) {
    margin-bottom: $dds-spacing-2;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

.Variants-SelectedSide {
  margin: $dds-spacing-2;

  @include media-breakpoint-up(md) {
    margin: $dds-spacing-4;
    max-width: $selected-side-tablet-desktop-width;
  }

  @include media-breakpoint-up(lg) {
    flex: 0 0 $selected-side-tablet-desktop-width;
  }

  .Variants-Label {
    @include media-breakpoint-up(lg) {
      margin-bottom: calc(#{$dds-spacing-8} + 2px);
    }
  }

  .Variants-BuyButton {
    margin: 0;

    .BuyButton-Button {
      @include dds-type-scale(0);

      border-radius: $dds-border-radius-global;
      font-weight: $dds-font-weight-bold;
      padding: $dds-spacing-1;
    }

    svg {
      @include dds-box($dds-sizing-3);

      margin-right: $dds-spacing-1;
    }
  }
}
