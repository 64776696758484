@import '~dd-client/site/common/styles/globals';

.Voting {
  align-items: center;
  display: flex;
  margin-top: $dds-spacing-1_5;
}

.Voting-Rating {
  @include dds-type-scale(-1);

  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  letter-spacing: rem(-0.43px);
  margin-right: $dds-spacing-1_5;
}

.Voting-Button {
  &.Button {
    width: $dds-spacing-4_5;

    svg {
      @include dds-box($dds-sizing-2);
    }
  }
}

.Voting-Button--Like {
  border-radius: $dds-sizing-1_5 0 0 $dds-sizing-1_5;
}

.Voting-Button--Dislike {
  border-radius: 0 $dds-sizing-1_5 $dds-sizing-1_5 0;
  margin-left: -1px;
  margin-right: $dds-spacing-1_5;

  &.Button--Primary {
    @include dds-button(var(--dds-gray-900), var(--dds-gray-800), var(--dds-black));

    svg {
      fill: var(--dds-white);
    }
  }
}

.Voting-ReplyButton {
  @include dds-button(
    $color: var(--dds-common-comment-box-rely-button-color),
    $color-hover: var(--dds-common-comment-box-rely-button-color-hover),
    $color-active: var(--dds-common-comment-box-rely-button-color-active),
  );

  font-weight: $dds-font-weight-semibold;
  text-transform: none;

  svg {
    fill: var(--dds-common-comment-box-rely-button-color);
  }

  &:active {
    svg {
      fill: var(--dds-common-comment-box-rely-button-color-active);
    }
  }

  &:hover {
    svg {
      fill: var(--dds-common-comment-box-rely-button-color-hover);
    }
  }
}
