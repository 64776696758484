@import '~dd-client/site/common/styles/globals';

.RetryButton {
  color: var(--dds-common-alert-warning-color);
  display: inline;
  font-family: $dds-font-family-base;
  font-weight: $dds-font-weight-regular;
  margin-left: $dds-spacing-0_5;
  text-decoration: underline;
  text-transform: none;
}
