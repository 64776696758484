@import '~dd-client/site/common/styles/globals';

.Links {
  @at-root .Details & {
    .Links-List {
      li::before {
        color: var(--dds-primary);
      }
    }
  }
}

.Links-ListLink {
  @include dds-link(var(--dds-common-link-text-color), var(--dds-common-link-text-color-hover), var(--dds-common-link-text-color-active));

  text-decoration: underline;
}
