@import '~dd-client/site/common/styles/globals';

@mixin cds-alert-type(
  $name,
  $color-icon-fill,
  $color-bg,
  $color-text,
) {
  &--#{$name} {
    background-color: $color-bg;
    color: $color-text;

    .Alert-Icon {
      fill: $color-icon-fill;
    }

    .Alert-Content {
      a {
        @include dds-link($color-text);

        text-decoration: underline;
      }
    }
  }
}

.Alert {
  @include cds-alert-type(
    Info,
    var(--dds-common-alert-info-icon-fill),
    var(--dds-common-alert-info-bg),
    var(--dds-common-alert-info-color),
  );
  @include cds-alert-type(
    Success,
    var(--dds-common-alert-success-icon-fill),
    var(--dds-common-alert-success-bg),
    var(--dds-common-alert-success-color),
  );
  @include cds-alert-type(
    Warning,
    var(--dds-common-alert-warning-icon-fill),
    var(--dds-common-alert-warning-bg),
    var(--dds-common-alert-warning-color),
  );

  align-items: center;
  border-radius: $dds-border-radius-global;
  display: flex;
  hyphens: auto;
  padding: $dds-spacing-1 $dds-spacing-2;
  word-break: break-word;

  strong {
    font-weight: $dds-font-weight-semibold;
  }
}

.Alert-Icon {
  @include dds-box($dds-sizing-3);

  align-self: flex-start;
  flex: 0 0 $dds-sizing-3;
}

.Alert-Content {
  margin-left: $dds-spacing-2;
}
