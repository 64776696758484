@import 'node_modules/swiper/swiper';
@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.Slider {
  position: relative;
}

.Swiper {
  overflow: unset;
  padding-bottom: $dds-spacing-4_5;

  @include media-breakpoint-up(lg) {
    overflow: hidden;
  }

  // when swiper is initialized override (remove) margin-right for slides, to make swiper work correctly
  &.swiper-initialized { /* stylelint-disable-line selector-class-pattern */
    .Swiper-Slide {
      margin-right: 0;
    }
  }
}

.Swiper-Slide {
  height: auto;
}

// Pagination
.swiper-pagination { /* stylelint-disable-line selector-class-pattern */
  @include dds-pos(absolute, $left: 0);

  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1;
}

.Slider-PaginationBullet {
  @include dds-box($dds-sizing-1_5);

  background-color: var(--dds-primary);
  border-radius: 50%;
  cursor: pointer;
  margin: 0 $dds-spacing-1;
  opacity: 0.5;
}

.Slider-PaginationBullet--Active {
  opacity: 1;
}

.swiper-pagination-lock { /* stylelint-disable-line selector-class-pattern */
  display: none;
}

.swiper-button-lock { /* stylelint-disable-line selector-class-pattern */
  display: none;
}
