@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.NewsletterDropdown {
  position: relative;
}

// wrapper needed for newsletter inside sold out box - fix for chrome (wrong box shadow behaviour)
.NewsletterDropdown-ToggleWrapper {
  overflow: hidden;
  padding-bottom: $dds-spacing-0_25;
}

.NewsletterDropdown-Toggle {
  @include dds-type-scale(0);

  align-items: center;
  box-shadow: 0 1px 0 0 var(--dds-input-border-color);
  color: var(--dds-gray-800);
  cursor: pointer;
  display: flex;
  font-weight: $dds-font-weight-semibold;
  justify-content: space-between;
  margin: 0 -1px;
  padding: $dds-spacing-0_75 1px;

  svg {
    @include dds-box($dds-sizing-2);

    fill: var(--dds-gray-500);
    flex-shrink: 0;
  }

  @at-root .NewsletterDropdown--Open & {
    box-shadow: 0 2px 0 0 var(--dds-input-border-color);
  }
}

.NewsletterDropdown-ToggleLabel {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.NewsletterDropdown-Content {
  @include dds-pos(absolute, $top: 100%);

  background-color: var(--dds-white);
  border-radius: $dds-border-radius-global * 2;
  box-shadow: 0 0 $dds-sizing-2 0 rgb(0 0 0 / 25%);
  padding: 0 $dds-spacing-2 $dds-spacing-1_5;
  visibility: hidden;
  width: 100%;
  z-index: 1;

  @at-root .NewsletterDropdown--Open & {
    visibility: visible;
  }
}

.NewsletterDropdown-CheckboxesWrapper {
  margin-bottom: $dds-spacing-1_5;
  margin-right: -$dds-spacing-2;

  .Checkbox {
    border-bottom: 1px solid var(--dds-gray-300);
    padding: $dds-spacing-1_5 0;

    &:not(:first-child) {
      margin-left: $dds-spacing-2;
    }
  }
}
