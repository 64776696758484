@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.Price {
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
}

.Price-PriceAndDiscount {
  align-items: center;
  display: flex;
}

.Price-Price {
  @include dds-type-scale(7);

  letter-spacing: rem(-1.08px);

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(11);

    letter-spacing: rem(-1.74px);
  }
}

.Price-Discount {
  @include dds-type-scale(1);

  align-items: center;
  background-color: var(--dds-deal-deal-page-product-main-price-discount-bg);
  border-radius: $dds-sizing-2;
  color: var(--dds-deal-deal-page-product-main-price-discount-color);
  display: flex;
  letter-spacing: rem(-0.54px);
  margin-left: $dds-spacing-1;
  padding: $dds-spacing-0_5 $dds-spacing-1;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(6);

    border-radius: $dds-sizing-3;
    letter-spacing: unset;
    margin-left: $dds-spacing-2;
    padding: $dds-spacing-0_5 $dds-spacing-1_5;
  }
}

.Price-DiscountPercentage {
  @include dds-type-scale(-1);

  margin-left: $dds-spacing-0_25;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(3);

    margin-left: $dds-spacing-0_5;
  }
}

.Price-OldPrice {
  align-items: center;
  display: flex;
}

.Price-OldPriceValue {
  @include dds-type-scale(1);

  color: var(--dds-deal-deal-page-product-main-price-old-price-value-color);
  letter-spacing: rem(-0.54px);
  text-decoration: line-through;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(4);

    letter-spacing: rem(-0.77px);
  }
}

.Price-InfoIcon {
  @include dds-box($dds-sizing-3);

  cursor: pointer;
  fill: var(--dds-deal-deal-page-product-main-price-old-price-value-color);
  margin-left: $dds-spacing-1;

  &:focus {
    outline: none;
  }
}

.Price-From {
  @include dds-type-scale(1);

  font-weight: $dds-font-weight-extrabold;
  text-transform: uppercase;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(6);

    letter-spacing: rem(-0.96px);
  }
}

.Price--Variant {
  .Price-Price {
    @include dds-type-scale(2);

    letter-spacing: rem(-0.61px);
  }

  .Price-Discount {
    @include dds-type-scale(-1);

    letter-spacing: rem(-0.42px);
    line-height: $dds-line-height-list;
    margin-left: $dds-spacing-1;
    padding: 0 $dds-spacing-0_5;
  }

  .Price-DiscountPercentage {
    @include dds-type-scale(-2);

    line-height: $dds-line-height-list;
    margin-left: $dds-spacing-0_25;
  }

  .Price-InfoIcon {
    @include dds-box($dds-sizing-2_5);
  }

  .Price-OldPriceValue {
    @include dds-type-scale(xsmall);

    letter-spacing: rem(-0.38px);
    line-height: $dds-line-height-condensed-small;
  }
}

.Price--Small {
  .Price-Price {
    @include dds-type-scale(1);

    letter-spacing: rem(-0.54px);
  }

  .Price-Discount {
    @include dds-type-scale(xsmall);

    letter-spacing: rem(-0.38px);
    line-height: $dds-line-height-condensed;
    margin-left: $dds-spacing-0_5;
    padding: 0 $dds-spacing-0_5;
  }

  .Price-DiscountPercentage {
    @include dds-type-scale(-4);

    line-height: $dds-line-height-condensed;
  }

  .Price-OldPriceValue {
    @include dds-type-scale(xsmall);

    letter-spacing: rem(-0.38px);
    line-height: $dds-line-height-condensed-small;
  }

  .Price-InfoIcon {
    @include dds-box($dds-sizing-2);

    margin-left: $dds-spacing-0_5;
  }

  .Price-From {
    @include dds-type-scale(-2);

    letter-spacing: rem(-0.38px);
    line-height: $dds-line-height-condensed;
    margin-right: $dds-spacing-0_5;
  }
}
