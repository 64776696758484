@import '~dd-client/site/common/styles/globals';

.ToggleSwitch {
  align-items: center;
  color: var(--dds-gray-500);
  cursor: pointer;
  display: flex;

  .ToggleSwitch-Slider {
    background: var(--dds-gray-100);
    border: 1px solid var(--dds-gray-200);
  }

  .ToggleSwitch-Slider::after {
    background: var(--dds-gray-400);
  }
}

.ToggleSwitch--Checked {
  color: var(--dds-common-toggle-switch-active-color);

  .ToggleSwitch-Slider {
    background: var(--dds-common-toggle-switch-active-bg);
    border: 1px solid var(--dds-common-toggle-switch-active-border-color);
  }

  .ToggleSwitch-Slider::after {
    background: var(--dds-common-toggle-switch-active-color);
  }
}

.ToggleSwitch-Input {
  height: 0;
  visibility: hidden;
  width: 0;
}

.ToggleSwitch-Input:checked + .ToggleSwitch-Slider::after {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.ToggleSwitch-Slider {
  @include dds-box($dds-sizing-4_5, $dds-sizing-2_5);

  border-radius: $dds-sizing-2;
  font-weight: $dds-font-weight-bold;
  line-height: $dds-line-height-condensed;
  margin-right: $dds-spacing-1;
  position: relative;
}

.ToggleSwitch-Slider::after {
  @include dds-pos(absolute, $top: 1px, $left: 1px);
  @include dds-box($dds-sizing-2);

  border-radius: $dds-sizing-2;
  content: '';
  transition: 0.25s;
}

.ToggleSwitch-Slider:active::after {
  width: $dds-sizing-3;
}
