@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

$dds-menu-modal-max-width: 320px;

.ModalMenu-Overlay {
  margin-top: $dds-spacing-5;

  @include media-breakpoint-up(md) {
    margin-top: $dds-spacing-8;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.ModalMenu-Modal {
  margin-top: $dds-spacing-5;
  padding: 0;

  @include media-breakpoint-up(md) {
    margin-top: $dds-spacing-8;
  }

  @include media-breakpoint-only(md) {
    border-radius: 0;
    max-width: $dds-menu-modal-max-width;
  }
}

.ModalMenu-Links {
  @include dds-list-unstyled;
  @include dds-type-scale(0);

  display: flex;
  flex-direction: column;
  margin: $dds-spacing-1_5 $dds-spacing-3;

  li {
    margin: $dds-spacing-1_5 0;
  }
}

.ModalMenu-LanguageWrapper {
  border-bottom: 1px solid var(--dds-gray-300);
  border-top: 1px solid var(--dds-gray-300);
  margin: $dds-spacing-1_5 $dds-spacing-3;
  padding: $dds-spacing-3 0;
}

.ModalMenu-BrackWrapper {
  align-items: center;
  display: flex;
  margin: $dds-spacing-5 $dds-spacing-3 0;
}

.ModalMenu-BrackText {
  @include dds-type-scale(-2);

  color: var(--dds-gray-500);
  font-weight: $dds-font-weight-semibold;
  line-height: $dds-line-height-list;
  margin-right: $dds-spacing-1;
}

.ModalMenu-BrackLogoLink {
  display: flex;
}
