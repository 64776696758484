@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.Newsletter-EmailInput {
  margin-bottom: $dds-spacing-2;
}

.Newsletter-Text {
  @include dds-type-scale(-2);

  color: var(--dds-gray-800);
  line-height: $dds-line-height-condensed;
  margin-bottom: $dds-spacing-0_5;
}

.Newsletter-Text--ProcessInformation {
  color: var(--dds-gray-500);
  margin-bottom: $dds-spacing-2;
}

.Newsletter-Dropdown {
  margin-bottom: $dds-spacing-2;
}

.Newsletter-Alert {
  margin-top: $dds-spacing-2;
}
