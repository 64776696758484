@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.TopHeader {
  @include dds-type-scale(-2, $margin-bottom: 0, $line-height: $dds-line-height-condensed);

  display: none;
  justify-content: space-between;
  padding: $dds-spacing-1 0;

  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.TopHeader-LinksWrapper {
  display: flex;
}

.TopHeader-LinkGroup {
  @include dds-list-unstyled(true);

  li {
    margin-right: $dds-spacing-3;
  }
}

.TopHeader-RightPart {
  display: flex;

  li {
    &:last-child {
      margin-right: 0;
    }
  }
}
