/* ==========================================================================
   Bootstrap Custom Grid
   ========================================================================== */

/**
 * For tablet and mobiles gutter is 8px
 */

/* stylelint-disable selector-class-pattern */

@use 'sass:math';

.container {
  padding-left: $dds-sizing-2;
  padding-right: $dds-sizing-2;

  @include media-breakpoint-up(md) {
    padding-left: $dds-sizing-3;
    padding-right: $dds-sizing-3;
  }

  @include media-breakpoint-up(lg) {
    padding-left: $dds-sizing-6;
    padding-right: $dds-sizing-6;
  }

  @include media-breakpoint-up(xl) {
    padding-left: 0;
    padding-right: 0;
  }
}

div,
section {
  &[class*='col'] {
    padding-left: $grid-gutter-width * 0.5;
    padding-right: $grid-gutter-width * 0.5;

    @include media-breakpoint-up(md) {
      padding-left: math.div($grid-gutter-width, 1.33);
      padding-right: math.div($grid-gutter-width, 1.33);
    }

    @include media-breakpoint-up(lg) {
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
    }
  }
}

.row {
  margin-left: -$grid-gutter-width * 0.5;
  margin-right: -$grid-gutter-width * 0.5;

  @include media-breakpoint-up(md) {
    margin-left: - math.div($grid-gutter-width, 1.33);
    margin-right: - math.div($grid-gutter-width, 1.33);
  }

  @include media-breakpoint-up(lg) {
    margin-left: -$grid-gutter-width;
    margin-right: -$grid-gutter-width;
  }
}
