/* ==========================================================================
   Custom Mixins
   ========================================================================== */

/**
 * dds-type-scale
 * Sets proper font-size & line-height based on global typography settings
 * Line-height (unitless) is calculated based on font-size to fit into vertical grid
 * Use margin-bottom property to include additional line below
 * Use line-height property to adjust leading (line-height)
 *
 * Examples:
 * @include dds-type-scale(1), @include dds-type-scale(h1,1);
 */

@use 'sass:math';

@mixin dds-type-scale($scale, $margin-bottom: 0, $line-height: $dds-line-height-base) {
  // returns number of half-lines given font-size occupies
  $line-count: ceil(math.div(map-get($dds-type-scales, $scale), ($line-height * 0.5)));
  $current-font-size: map-get($dds-type-scales, $scale);
  $current-line-height: math.div($line-count * ($line-height * 0.5), $current-font-size);

  font-size: rem($current-font-size);
  line-height: $current-line-height + 0.00001;

  @if $margin-bottom != 0 {
    margin: 0 0 rem($margin-bottom * $dds-line-height-base);
  } @else {
    margin: 0;
  }
}

/**
 * dds-font-size
 * Retrieves proper font-size from font-sizing map
 *
 * Examples:
 * @include dds-font-size(small);
 */

@mixin dds-font-size($scale) {
  $current-font-size: map-get($dds-type-scales, $scale);

  font-size: rem($current-font-size);
}

/**
 * dds-BOX
 * Sets both height and width of element
 */

@mixin dds-box($width, $height: $width) {
  height: $height;
  width: $width;
}

/**
 * dds-FORM-PLACEHOLDER
 */

@mixin dds-input-placeholder($color: var(--dds-input-placeholder-color), $opacity: null) {
  /* stylelint-disable selector-no-vendor-prefix */
  &::-webkit-input-placeholder {
    color: $color;
    opacity: $opacity;
  }

  &::-moz-placeholder {
    color: $color;
    opacity: $opacity;
  }

  &:-moz-placeholder {
    color: $color;
    opacity: $opacity;
  }

  &::-ms-input-placeholder {
    color: $color;
    opacity: $opacity;
  }

  &:-ms-input-placeholder {
    color: $color;
    opacity: $opacity;
  }

  &::placeholder {
    color: $color;
    opacity: $opacity;
  }
  /* stylelint-enable selector-no-vendor-prefix */
}

/**
 * dds-POS
 * Shortcut for positioning properties
 */

@mixin dds-pos($position, $top: null, $right: null, $bottom: null, $left: null) {
  bottom: $bottom;
  left: $left;
  position: $position;
  right: $right;
  top: $top;
}

/**
 * dds-BUTTON
 * Sets background color and border for different button states
 */

@mixin dds-button(
  $bg: null,
  $bg-hover: $bg,
  $bg-active: $bg,
  $bg-focus: null,
  $border: null,
  $color: null,
  $color-hover: null,
  $color-active: null,
) {
  background-color: $bg;
  color: $color;

  @if $border {
    box-shadow: inset 0 0 0 1px $border;
  }

  &:hover {
    background-color: $bg-hover;
    color: $color-hover;
  }

  &:active {
    background-color: $bg-active;
    color: $color-active;
  }

  &:focus {
    background-color: $bg-focus;
  }
}

/**
 * dds-INPUT-BORDER
 * default input border
 */

@mixin dds-input-border($border-color: var(--dds-input-border-color)) {
  border: 1px solid $border-color;
}

/**
 * dds-UL-UNSTYLED
 * Removes default styles on ul, ol elements
 * Use instead of writing these rules manually
 */

@mixin dds-list-unstyled($isInline: true) {
  list-style: none;
  margin: 0;
  padding: 0;

  @if $isInline {
    & > li {
      display: inline-block;
    }
  }
}

/**
 * dds-LINK
 * Creates styles for all link states
 */

@mixin dds-link($link-color: var(--dds-text-body), $hover: $link-color, $active: $link-color, $visited: $link-color) {
  color: $link-color;
  text-decoration: none;

  &:hover {
    color: $hover;
    text-decoration: underline;
  }

  &:visited {
    color: $visited;
  }

  &:visited:hover {
    color: $hover;
  }

  &:active {
    color: $active;
  }

  &:visited:active {
    color: $active;
  }
}

@mixin dds-content-text-links {
  a {
    @include dds-link(var(--dds-common-link-text-color), var(--dds-common-link-text-color-hover), var(--dds-common-link-text-color-active));

    text-decoration: underline;
  }
}

@mixin dds-content-img {
  img {
    height: auto;
    max-width: 100%;
  }
}

@mixin dds-text-ellipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin dds-tabs-content {
  @include dds-type-scale(1);
  @include dds-content-img;

  h3 {
    @include dds-font-size(1);

    margin-bottom: $dds-spacing-1;
  }

  ul:not(.ContentNavigation-List) {
    @include dds-list-unstyled(false);

    margin-bottom: $dds-spacing-1;

    li {
      display: block;
      padding: 0 0 0 $dds-spacing-3;
      position: relative;

      p {
        display: inline-block;
        margin: 0;
      }

      &::before {
        @include dds-pos(absolute, $left: 0);

        content: '•';
        margin-left: $dds-spacing-1;
        margin-right: $dds-spacing-1;
      }
    }
  }

  ol {
    margin-bottom: $dds-spacing-1;

    p {
      display: inline-block;
      margin: 0;
    }
  }

  p {
    @include dds-type-scale(1, 1);
  }
}

@mixin dds-buy-button-bg-color {
  background-color: var(--dds-deal-deal-page-product-main-buy-button-bg);

  &:hover {
    background-color: var(--dds-deal-deal-page-product-main-buy-button-bg-hover);
  }

  &:active {
    background-color: var(--dds-deal-deal-page-product-main-buy-button-bg-active);
  }
}

/**
 * dds-FORM-INPUT
 * Sets base styling for all form inputs
 */

@mixin dds-form-input {
  /* stylelint-disable property-no-vendor-prefix */
  @include dds-input-placeholder;
  @include dds-type-scale(body);

  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-box-shadow: inset 0 0 0 1px var(--dds-input-border-color);
  /* stylelint-disable-next-line order/properties-alphabetical-order */
  appearance: none;
  border: 0;
  border-radius: $dds-border-radius-global;
  box-shadow: inset 0 0 0 1px var(--dds-input-border-color);
  color: var(--dds-gray-800);
  font-weight: $dds-font-weight-semibold;
  padding: $dds-spacing-0_75 $dds-spacing-1_5;
  width: 100%;
  /* stylelint-enable property-no-vendor-prefix */

  &:focus {
    box-shadow: inset 0 0 0 2px var(--dds-input-border-color);
    outline: none;
  }

  &:disabled {
    background-color: var(--dds-gray-100);
  }
}

/**
 * dds-FORM-INPUT-ERROR
 * Sets base styling for all input errors
 */

@mixin dds-form-input-error {
  box-shadow: inset 0 0 0 1px var(--dds-error);

  &:focus {
    box-shadow: inset 0 0 0 2px var(--dds-error);
  }
}

/**
 * dds-SLIDE-MARGIN-RIGHT
 * Sets base margin-right for slides before the slider is initialized by js
 */

@mixin dds-slide-margin-right {
  margin-right: $dds-spacing-2;

  @include media-breakpoint-up(md) {
    margin-right: $dds-spacing-3;
  }

  @include media-breakpoint-up(lg) {
    margin-right: $dds-spacing-4;
  }
}
