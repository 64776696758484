@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.NewsPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: $dds-spacing-2;

  @include media-breakpoint-up(md) {
    padding-top: $dds-spacing-4;
  }

  @include media-breakpoint-up(lg) {
    padding-top: $dds-spacing-6;
  }
}

.NewsPage-Content {
  background-color: var(--dds-gray-100);
  flex-grow: 1;
  padding: $dds-spacing-2 0 $dds-spacing-6;

  @include media-breakpoint-up(lg) {
    padding: $dds-spacing-6 0 $dds-spacing-12;
  }
}

.NewsPage-List {
  @include dds-list-unstyled(false);

  display: flex;
  flex-wrap: wrap;

  @include media-breakpoint-only(md) {
    margin: 0 #{-$dds-spacing-1_5};
  }
}

.NewsPage-Loader {
  margin: auto;
}
