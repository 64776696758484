/* ==========================================================================
   Design Tokens
   ========================================================================== */

/* Sizing
   ========================================================================== */

/**
* This is a base (micro-grid) for all measurements in UI Components
* base unit = 8px
*/

$dds-sizing-base: 0.5rem !default;
$dds-sizing-0_25: $dds-sizing-base * 0.25 !default; // 2px
$dds-sizing-0_5:  $dds-sizing-base * 0.5 !default; // 4px
$dds-sizing-1:    $dds-sizing-base * 1 !default; // 8px
$dds-sizing-1_5:  $dds-sizing-base * 1.5 !default; // 12px
$dds-sizing-2:    $dds-sizing-base * 2 !default; // 16px
$dds-sizing-2_25: $dds-sizing-base * 2.25 !default; // 18px
$dds-sizing-2_5:  $dds-sizing-base * 2.5 !default; // 20px
$dds-sizing-3:    $dds-sizing-base * 3 !default; // 24px
$dds-sizing-3_5:  $dds-sizing-base * 3.5 !default; // 28px
$dds-sizing-4:    $dds-sizing-base * 4 !default; // 32px
$dds-sizing-4_5:  $dds-sizing-base * 4.5 !default; // 36px
$dds-sizing-5:    $dds-sizing-base * 5 !default; // 40px
$dds-sizing-5_5:  $dds-sizing-base * 5.5 !default; // 44px
$dds-sizing-6:    $dds-sizing-base * 6 !default; // 48px
$dds-sizing-7:    $dds-sizing-base * 7 !default; // 56px
$dds-sizing-8:    $dds-sizing-base * 8 !default; // 64px
$dds-sizing-8_5:  $dds-sizing-base * 8.5 !default; // 68px
$dds-sizing-9:    $dds-sizing-base * 9 !default; // 72px
$dds-sizing-10:   $dds-sizing-base * 10 !default; // 80px
$dds-sizing-12:   $dds-sizing-base * 12 !default; // 96px
$dds-sizing-15:   $dds-sizing-base * 15 !default; // 120px
$dds-sizing-17:   $dds-sizing-base * 17 !default; // 136px
$dds-sizing-18:   $dds-sizing-base * 18 !default; // 144px
$dds-sizing-20:   $dds-sizing-base * 20 !default; // 160px
$dds-sizing-24:   $dds-sizing-base * 24 !default; // 192px
$dds-sizing-25:   $dds-sizing-base * 25 !default; // 200px
$dds-sizing-29:   $dds-sizing-base * 29 !default; // 232px
$dds-sizing-30:   $dds-sizing-base * 30 !default; // 240px
$dds-sizing-48:   $dds-sizing-base * 48 !default; // 384px
$dds-sizing-50:   $dds-sizing-base * 50 !default; // 400px
$dds-sizing-86:   $dds-sizing-base * 86 !default; // 688px


/* Spacing
   ========================================================================== */

/**
 * Spacing based on $dds-sizing-base unit
 * Usage: margins, paddings, positioning
 */

$dds-spacing-base: $dds-sizing-base !default;
$dds-spacing-0_25:  $dds-spacing-base * 0.25 !default; // 2px
$dds-spacing-0_5:   $dds-spacing-base * 0.5 !default; // 4px
$dds-spacing-0_75:  $dds-spacing-base * 0.75 !default; // 6px
$dds-spacing-1:     $dds-spacing-base * 1 !default; // 8px
$dds-spacing-1_5:   $dds-spacing-base * 1.5 !default; // 12px
$dds-spacing-2:     $dds-spacing-base * 2 !default; // 16px
$dds-spacing-2_5:   $dds-spacing-base * 2.5 !default; // 20px
$dds-spacing-2_75:  $dds-spacing-base * 2.75 !default; // 22px
$dds-spacing-3:     $dds-spacing-base * 3 !default; // 24px
$dds-spacing-3_5:   $dds-spacing-base * 3.5 !default; // 28px
$dds-spacing-3_75:  $dds-spacing-base * 3.75 !default; // 30px
$dds-spacing-4:     $dds-spacing-base * 4 !default; // 32px
$dds-spacing-4_5:   $dds-spacing-base * 4.5 !default; // 36px
$dds-spacing-5:     $dds-spacing-base * 5 !default; // 40px
$dds-spacing-6:     $dds-spacing-base * 6 !default; // 48px
$dds-spacing-7:     $dds-spacing-base * 7 !default; // 56px
$dds-spacing-8:     $dds-spacing-base * 8 !default; // 64px
$dds-spacing-9:     $dds-spacing-base * 9 !default; // 72px
$dds-spacing-10:    $dds-spacing-base * 10 !default; // 80px
$dds-spacing-12:    $dds-spacing-base * 12 !default; // 96px
$dds-spacing-12_5:  $dds-spacing-base * 12.5 !default; // 100px
$dds-spacing-15:    $dds-spacing-base * 15 !default; // 120px
$dds-spacing-16:    $dds-spacing-base * 16 !default; // 128px
$dds-spacing-20:    $dds-spacing-base * 20 !default; // 160px
$dds-spacing-24:    $dds-spacing-base * 24 !default; // 192px
$dds-spacing-25:    $dds-spacing-base * 25 !default; // 200px
$dds-spacing-30:    $dds-spacing-base * 30 !default; // 240px


/* Typography & font-sizing
   ========================================================================== */

$dds-font-family-base: 'Open Sans', 'Segoe UI', tahoma, sans-serif !default;
$dds-font-family-secondary: 'Montserrat', sans-serif !default;
$dds-font-size-root:              16px !default;
$dds-font-size-base:              15px !default;
$dds-line-height-large:           $dds-font-size-root * 1.75 !default; // 28px
$dds-line-height-base:            $dds-font-size-root * 1.5 !default; // 24px
$dds-line-height-list:            $dds-font-size-root * 1.25 !default; // 20px
$dds-line-height-condensed:       $dds-font-size-root * 1 !default; // 16px
$dds-line-height-condensed-small: $dds-font-size-root * 0.75 !default; // 12px

$dds-font-weight-regular:   400 !default;
$dds-font-weight-semibold:  600 !default;
$dds-font-weight-bold:      700 !default;
$dds-font-weight-extrabold: 800 !default;
$dds-type-scales: (
  -5: 9px,
  -4: 9.99px,
  -3: 11.24px,
  -2: 12.64px,
  -1: 14.22px,
  0:  16px,
  1:  18px,
  2:  20.25px,
  3:  22.78px,
  4:  25.63px,
  5:  28.83px,
  6:  32.44px,
  7:  36.49px,
  8:  41.05px,
  9:  46.18px,
  10: 51.96px,
  11: 58px,
  /**
   * Aliases
   */
  /**
   * Mobile sizes
   */

  xsmall-m:  12.64px,
  small-m:   14.22px,
  body-sm-m: 14.22px,
  body-m:    16px,
  body-lg-m: 18px,
  h6-m:      14.22px,
  h5-m:      14.22px,
  h4-m:      16px,
  h3-m:      16px,
  h2-m:      18px,
  h1-m:      22.78px,

  /**
   * Desktop sizes
   */

  xsmall:  12.64px,
  small:   14.22px,
  body-sm: 14.22px,
  body:    16px,
  body-lg: 18px,
  h6:      14.22px,
  h5:      16px,
  h4:      18px,
  h3:      20.25px,
  h2:      22.78px,
  h1:      32.44px,
) !default;


/* Borders
   ========================================================================== */

$dds-border-radius-global: 0.25rem !default; // 4px
$dds-box-shadow: 0 $dds-spacing-1 $dds-spacing-2 0 rgb(0 0 0 / 25%) !default;


/* Animation / Transition durations
   ========================================================================== */

$dds-transition-base: all 250ms ease-in-out !default;
$dds-transition-timing: 250ms !default;
$dds-transition-curve: ease-in-out !default;

/* Sticky Bar
   ========================================================================== */

$dds-sticky-bar-mobile-height: $dds-sizing-8_5;

/* z-indexes
   ========================================================================== */

// Getback flyout has z-index: 1000
$dds-zindex-promotion: 998 !default;
$dds-zindex-sticky: 999 !default;
$dds-zindex-modal-overlay: 1001 !default;
$dds-zindex-modal: 1002 !default;
$dds-zindex-modal-header: 1003 !default;
$dds-zindex-full-page-animation: 1004 !default;
