@import '~dd-client/site/common/styles/globals';

$unsubscription-page-content-max-width: 690px;

.NewsletterUnsubscriptionPage {
  padding-bottom: $dds-spacing-6;
  padding-top: $dds-spacing-8;
}

.NewsletterUnsubscriptionPage-Content {
  margin: auto;
  max-width: $unsubscription-page-content-max-width;
}

.NewsletterUnsubscriptionPage-Title {
  margin-bottom: $dds-spacing-2;
}

.NewsletterUnsubscriptionPage-Text {
  margin-bottom: $dds-spacing-4_5;
}

.NewsletterUnsubscriptionPage-Loader {
  margin: auto;
}

.NewsletterUnsubscriptionPage-Form {
  .Checkbox {
    padding: $dds-spacing-1_5 0;

    &:not(:first-child) {
      margin-left: $dds-spacing-2;
    }
  }

  & + .Alert {
    margin-top: $dds-spacing-2;
  }
}

.NewsletterUnsubscriptionPage-ButtonsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: $dds-spacing-2;
  justify-content: space-between;
  margin-top: $dds-spacing-4_5;
}
