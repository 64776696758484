@import '~dd-client/site/common/styles/globals';

$max-video-width: 1200px;

.Video-Button {
  @include dds-pos(absolute, $left: calc(50% - #{$dds-spacing-5}), $top: calc(50% - #{$dds-spacing-5}));
}

.Video-Box {
  @include dds-pos(fixed, $left: 0, $top: 0);

  background-color: rgb(0 0 0 / 40%);
  height: 100%;
  padding: $dds-spacing-2;
  width: 100%;
  z-index: $dds-zindex-modal-overlay;
}

.Video-Content {
  height: 100%;
  margin: 0 auto;
  max-width: $max-video-width;
  position: relative;
  width: 100%;
}

.Video-IframeWrapper {
  @include dds-pos(absolute, $left: 50%, $top: 50%);

  padding-bottom: 56.25%;
  transform: translateX(-50%) translateY(-50%);
  width: 100%;

  iframe {
    @include dds-pos(absolute, $left: 0, $top: 0, $bottom: 0, $right: 0);

    background-color: var(--dds-black);
    border-radius: $dds-border-radius-global * 2;
    box-shadow: 0 0 $dds-sizing-8 0 rgb(0 0 0 / 25%);
    height: 100%;
    width: 100%;
  }
}

.Video-CloseButton {
  @include dds-box($dds-sizing-6);
  @include dds-pos(absolute, $right: 0, $bottom: calc(100% + #{$dds-spacing-2}));

  background-color: var(--dds-white);
  border: 0;
  border-radius: 50%;
  padding: 0;

  svg {
    @include dds-box($dds-sizing-4);

    fill: var(--dds-gray800);
  }
}
