@import '~dd-client/site/common/styles/globals';

$dds-mobile-image-width: 144px;

.CategoryItem {
  background: var(--dds-app-header-category-menu-item-bg);
  border: 1px solid var(--dds-app-header-category-menu-item-border-color);
  border-radius: $dds-border-radius-global;
  display: flex;
  flex: 1 0 $dds-sizing-18;
  flex-direction: column;
  margin-right: $dds-spacing-1;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  width: 0;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    text-decoration: none;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      border: 1px solid var(--dds-app-header-category-menu-item-border-color-hover);

      .CategoryItem-Category {
        color: var(--dds-app-header-category-menu-item-category-color-hover);
      }
    }
  }

  &.CategoryItem--Active {
    border: 1px solid var(--dds-app-header-category-menu-item-active-border-color);

    .CategoryItem-Category {
      background-color: var(--dds-app-header-category-menu-item-active-category-bg);
      color: var(--dds-app-header-category-menu-item-active-category-color);
    }
  }

  @at-root .CategoryMenu--Mobile & {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    margin-bottom: $dds-spacing-1;
    margin-right: 0;
    width: auto;

    .CategoryItem-Headline {
      @include dds-font-size(xsmall);
      @include dds-text-ellipsis;

      color: var(--dds-app-header-category-menu-item-headline);
      letter-spacing: -0.38px;
    }

    .CategoryItem-Category {
      display: flex;
      padding: 0 $dds-spacing-1_5;
    }

    .CategoryItem-Countdown {
      @include dds-pos(static, $bottom: unset, $right: unset);

      margin-left: auto;
    }

    .CategoryItem-Image {
      margin-bottom: 0;
    }

    .CategoryItem-Content {
      display: flex;
    }

    .CategoryItem-BottomContent {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      max-width: calc(100% - #{$dds-mobile-image-width});
      padding-bottom: $dds-spacing-1_5;
      padding-top: $dds-spacing-3;
    }

    .CategoryItem-NewLabel {
      bottom: calc(100% - #{$dds-spacing-4_5});
    }

    .CategoryItem-NewLabel--Visible {
      bottom: calc(100% - #{$dds-spacing-2_5});
    }

    .CategoryItem-Price {
      margin-top: auto;
    }
  }

  @at-root .CategoryMenu-Slider & {
    margin-bottom: 0;
  }
}

.CategoryItem-Category {
  @include dds-type-scale(-4);
  @include dds-text-ellipsis;

  color: var(--dds-app-header-category-menu-item-category-color);
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  line-height: $dds-line-height-base;
  margin-bottom: $dds-spacing-1;
  padding: 0 $dds-spacing-1;
  text-align: center;
  text-transform: uppercase;
}

.CategoryItem-Countdown {
  @include dds-pos(absolute, $bottom: $dds-spacing-2, $right: $dds-spacing-1);

  color: var(--dds-gray-400);

  svg {
    fill: var(--dds-gray-400);
  }
}

.CategoryItem-Image {
  margin-bottom: $dds-spacing-1;
  text-align: center;
}

.CategoryItem-BottomContent {
  color: var(--dds-text-body);
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  padding: 0 $dds-spacing-1 $dds-spacing-1;
  position: relative;
}

.CategoryItem-NewLabel {
  @include dds-pos(absolute, $bottom: calc(100% - #{$dds-spacing-1_5}), $left: $dds-spacing-1);
  @include dds-type-scale(-5);

  background-color: var(--dds-neon-yellow);
  border-radius: $dds-border-radius-global * 0.5;
  opacity: 0;
  padding: 0 $dds-spacing-0_5;
  text-transform: uppercase;
}

.CategoryItem-NewLabel--Visible {
  bottom: calc(100% + #{$dds-spacing-1});
  opacity: 1;
  transition-duration: 1s;
  transition-property: bottom, opacity;
  transition-timing-function: cubic-bezier(.22,1,.36,1);
}

.CategoryItem-Name {
  @include dds-font-size(xsmall);
  @include dds-text-ellipsis;

  letter-spacing: -0.38px;
  line-height: $dds-line-height-condensed;
}

.CategoryItem-ProgressBar {
  justify-self: flex-end;
  margin-top: auto;
}
