@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.SoldOutInfo {
  color: var(--dds-deal-deal-page-product-main-sold-out-info-color);
  margin-bottom: $dds-spacing-2;

  @at-root .ProductMain--HomePage & {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}
