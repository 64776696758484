@import '~dd-client/site/common/styles/globals';

.TextInput {
  position: relative;
}

.TextInput-Input {
  @include dds-form-input;
}

.TextInput--Error {
  .TextInput-Input {
    @include dds-form-input-error;
  }
}

.TextInput--Disabled {
  opacity: 0.5;
}
