@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';


.Footer-Top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
