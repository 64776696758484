@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.NewsletterPage {
  padding-bottom: $dds-spacing-10;
  padding-top: $dds-spacing-10;
  text-align: center;

  @include media-breakpoint-up(lg) {
    padding-top: $dds-spacing-16;
  }
}

.NewsletterPage-Title {
  margin-bottom: $dds-spacing-6;
}

.NewsletterPage-Icon {
  @include dds-box($dds-sizing-8);

  fill: var(--dds-primary);
  margin-bottom: $dds-spacing-4;
}

.NewsletterPage-Button {
  margin: 0 auto;
}
