@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.DealPage {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    .DealPage-ProductMain {
      order: 1;
    }

    .DealPage-UniqueSellingPoints {
      order: 2;
    }

    .DealPage-ProductDetails {
      order: 3;
    }

    .DealPage-Comments {
      order: 4;
    }

    .DealPage-RecommendedProducts {
      order: 5;
    }

    .DealPage-News {
      order: 6;
    }
  }
}

.DealPage--HomePage {
  .DealPage-ProductDetails,
  .DealPage-Comments,
  .DealPage-RecommendedProducts,
  .DealPage-News,
  .DealPage-UniqueSellingPoints {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.DealPage-Loader {
  margin: $dds-spacing-6 auto;
}

// animation for mobiles
@mixin deal-page-mobile-animation($delay) {
  animation: fade-in 250ms ease-in $delay forwards;
  opacity: 0;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.DealPage--MobileAnimation {
  .ProductMain-CategoryName,
  .ProductMain-Title,
  .ProductMain-Subtitle {
    @include deal-page-mobile-animation(0ms);
  }

  .ProductMain-ImageWrapper {
    @include deal-page-mobile-animation(50ms);
  }

  .ProductMain-ProgressBar {
    @include deal-page-mobile-animation(100ms);
  }

  .ProductMain-Price {
    @include deal-page-mobile-animation(150ms);
  }

  .ProductMain-SoldOutInfo,
  .ProductMain-ReservedInfo,
  .ProductMain-BuyButton,
  .ProductMain-DeliveryInfo {
    @include deal-page-mobile-animation(200ms);
  }

  .ProductMain-BulletPoints {
    @include deal-page-mobile-animation(250ms);
  }

  .DealPage-ProductDetails,
  .DealPage-Comments,
  .DealPage-News,
  .DealPage-UniqueSellingPoints {
    @include deal-page-mobile-animation(300ms);
  }

  // home page
  &.DealPage--HomePage {
    .ProductMain-ImageWrapper {
      @include deal-page-mobile-animation(0ms);
    }

    .ProductMain-ProgressBar {
      @include deal-page-mobile-animation(50ms);
    }

    .ProductMain-Title,
    .ProductMain-Subtitle {
      @include deal-page-mobile-animation(100ms);
    }

    .ProductMain-Price,
    .ProductMain-SeeDealButton {
      @include deal-page-mobile-animation(150ms);
    }

    .CategoryMenu--Mobile,
    .StaticPromotion {
      @include deal-page-mobile-animation(200ms);
    }
  }
}
