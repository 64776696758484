@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.SeeDealButton {
  @include dds-buy-button-bg-color;

  margin-bottom: $dds-spacing-2;

  @include media-breakpoint-up(md) {
    display: none;
  }
}
