@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.CommentList--StickyHeader {
  .CommentList-HeaderWrapper {
    box-shadow: 0 0 $dds-spacing-2 0 rgb(0 0 0 / 15%);
  }
}

.CommentList-HeaderWrapper {
  align-items: center;
  background-color: var(--dds-white);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -#{$dds-spacing-2};
  padding: $dds-spacing-2;
  position: sticky;
  top: 0;
  z-index: $dds-zindex-modal-header;

  @include media-breakpoint-up(md) {
    margin: 0 -#{$dds-spacing-3};
    padding: $dds-spacing-2 $dds-spacing-3;
  }
}

.CommentList-CloseButton {
  @include dds-pos(absolute, $right: $dds-spacing-2, $top: $dds-spacing-2);

  svg {
    @include dds-box($dds-sizing-4);

    fill: var(--dds-gray-500);
  }

  @include media-breakpoint-up(md) {
    left: $dds-spacing-3;
    right: unset;
  }
}

.CommentList-Title {
  @include dds-type-scale(1);

  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  letter-spacing: rem(-0.54px);
  line-height: $dds-sizing-4;
  margin-right: $dds-spacing-1;

  @include media-breakpoint-up(md) {
    @include dds-type-scale(3);

    letter-spacing: rem(-0.68px);
    line-height: $dds-sizing-4;
    margin-left: $dds-spacing-5;
  }
}

.CommentList-WriteCommentButton {
  margin-right: $dds-spacing-7;

  @include media-breakpoint-up(md) {
    margin: 0;
  }
}

.CommentList-WriteCommentForm {
  margin-bottom: $dds-spacing-4;
}

.CommentList-LoadNewCommentsButton {
  margin-bottom: $dds-spacing-4;
  margin-top: $dds-spacing-1;
}

.CommentList-List {
  margin-top: $dds-spacing-1;

  .CommentBox {
    margin-bottom: $dds-sizing-4;
  }
}

.CommentList-Replies {
  border-left: $dds-sizing-0_25 solid var(--dds-gray-300);
  margin-bottom: $dds-sizing-4;
  padding-left: $dds-spacing-3;

  .CommentBox {
    &:last-child {
      margin-bottom: 0;
    }
  }

  & + .CommentList-ReplyCommentWrapper {
    margin-top: -$dds-sizing-4;
    padding-top: $dds-sizing-4;
  }
}

.CommentList-ReplyCommentWrapper {
  border-left: $dds-sizing-0_25 solid var(--dds-gray-300);
  margin-bottom: $dds-sizing-4;
  padding-left: $dds-spacing-3;
}

.CommentList-Alert {
  margin-bottom: $dds-spacing-3;
  margin-top: -$dds-spacing-2;
}
