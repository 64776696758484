@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

$product-label-color: #e2011a;
$product-label-new-color: #347d9f;

.ProductLabels {
  column-gap: $dds-spacing-1;
  display: flex;
  flex-wrap: wrap;
}

.ProductLabels-Label {
  @include dds-type-scale(xsmall);

  color: $product-label-color;
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  letter-spacing: rem(-0.38px);
  line-height: $dds-line-height-condensed-small;
  text-transform: uppercase;
}

.ProductLabels-Label--New {
  color: $product-label-new-color;
}
