@import '~dd-client/site/common/styles/globals';

.Tooltip {
  /* stylelint-disable-next-line selector-class-pattern */
  &.react-tooltip {
    @include dds-type-scale(-2);

    background-color: var(--dds-gray-600);
    z-index: 1;
  }
}
