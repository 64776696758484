/* ==========================================================================
   Fonts & Typography
   ========================================================================== */

@import '//fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap';
@import '//fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700;800';

html {
  font-size: $dds-font-size-root;

  &.HtmlElement--ScrollLock {
    overflow: hidden;
  }
}

body {
  @include dds-type-scale(body);

  background-color: var(--dds-white);
  color: var(--dds-text-body);
  font-family: $dds-font-family-base;
  font-feature-settings: 'liga', 'clig';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant-ligatures: common-ligatures;
  font-weight: $dds-font-weight-regular;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(body);
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.SectionTitle {
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
}

h1 {
  @include dds-type-scale(h1-m);

  letter-spacing: rem(-0.68px);
  margin-bottom: $dds-spacing-2;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(h1, 1);

    letter-spacing: rem(-0.97px);
  }
}

h2 {
  @include dds-type-scale(h2-m, 1);

  letter-spacing: -0.034rem;

  @include media-breakpoint-up(md) {
    @include dds-type-scale(h2, 1);

    letter-spacing: -0.0425rem;
  }
}

h3 {
  @include dds-type-scale(h3-m, 1);

  letter-spacing: -0.03rem;

  @include media-breakpoint-up(md) {
    @include dds-type-scale(h3, 1);

    letter-spacing: -0.0385rem;
  }
}

h4 {
  @include dds-type-scale(h4-m, 1);

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(h4, 1);
  }
}

h5 {
  @include dds-type-scale(h5-m, 1);

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(h5, 1);
  }
}

h6 {
  @include dds-type-scale(h6-m, 1);

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(h6, 1);
  }
}

p {
  @include dds-type-scale(body-m, 1);

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(body, 1);
  }
}

small {
  @include dds-type-scale(small-m);
}

strong {
  font-weight: $dds-font-weight-bold;
}

.SectionTitle {
  @include dds-type-scale(h1-m, 1);

  letter-spacing: -0.0425rem;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(h1, 1);

    letter-spacing: -0.06rem;
  }
}

.CapitalLetters {
  /* stylelint-disable-next-line declaration-no-important */
  text-transform: uppercase !important;

  .Button,
  input::placeholder{
    /* stylelint-disable-next-line declaration-no-important */
    text-transform: uppercase !important;
  }
}
