@import '~dd-client/site/common/styles/globals';

.FullPageAnimation {
  @include dds-box(100%);
  @include dds-pos(fixed, $top: 0, $left: 0);

  z-index: $dds-zindex-full-page-animation;
}

.FullPageAnimation-Background {
  @include dds-box(100%);
  @include dds-pos(absolute, $top: 0, $left: 0);

  animation: fade-out 0.1s linear 0.6s forwards;
  background-color: var(--dds-white);
}

.FullPageAnimation-ImageWrapper {
  @include dds-pos(absolute, $bottom: 0);

  animation:
  // 0.1s delay is needed - animation doesn't work on safari without it
  image-from-bottom-to-top 0.2s cubic-bezier(0.42, 0, 0.6, 1.01) 0.1s forwards,
  image-from-top-to-bottom 0.2s ease-in 0.8s forwards;
  transform: translate3d(0,100%,0);
  width: 100%;
}

.FullPageAnimation-Image {
  display: block;
  margin: 0 auto;
  max-height: 80vh;
  max-width: 100%;
}

.FullPageAnimation-Glow {
  @include dds-box(1px);
  @include dds-pos(absolute, $top: 2%, $left: 49%);

  animation: glow 0.2s linear 0.4s forwards;
  background-color: rgb(255 0 0 / 50%);
  border-radius: 50%;
  box-shadow: 0 0 75px 45px red;
  opacity: 0;
}

.FullPageAnimation-Flash {
  @include dds-box(100%);
  @include dds-pos(absolute, $top: 0, $left: 0);

  animation: flash 0.3s ease-in 0.5s forwards;
  background: linear-gradient(180deg, rgb(213 86 97 / 75%) 0%, #fff 26%, rgb(213 86 97 / 80%) 100%);
  opacity: 0;
}

@keyframes image-from-bottom-to-top {
  0% {
    transform: translate3d(0,100%,0);
  }

  80% {
    transform: translate3d(0,0,0);
  }

  100% {
    transform: translate3d(0,5%,0);
  }
}

@keyframes image-from-top-to-bottom {
  0% {
    transform: translate3d(0,5%,0) rotate(0);
  }

  100% {
    transform: translate3d(0,100%,0) rotate(-45deg);
  }
}

@keyframes glow {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes flash {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
