@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.PastDealsPage {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: $dds-spacing-2;

  @include media-breakpoint-up(md) {
    padding-top: $dds-spacing-4;
  }

  @include media-breakpoint-up(lg) {
    padding-top: $dds-spacing-6;
  }
}

.PastDealsPage-SearchForm {
  margin-bottom: $dds-spacing-3;
  max-width: 100%;
  position: relative;
  width: $dds-sizing-20 * 2;

  @include media-breakpoint-up(lg) {
    margin-bottom: $dds-spacing-4;
  }
}

.PastDealsPage-TextInput {
  width: 100%;
}

.PastDealsPage-SearchCloseButton {
  &.Button {
    @include dds-pos(absolute, $top: 0, $right: $dds-spacing-4);

    height: 100%;

    svg {
      fill: var(--dds-gray-500);
    }
  }
}

.PastDealsPage-SearchButton {
  &.Button {
    @include dds-pos(absolute, $top: 0, $right: 0);

    height: 100%;

    svg {
      fill: var(--dds-gray-500);
    }
  }
}

.PastDealsPage-SearchResult {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $dds-spacing-3;
  margin-top: -$dds-spacing-1;

  @include media-breakpoint-up(lg) {
    margin-bottom: $dds-spacing-4;
  }
}

.PastDealsPage-SearchResultText {
  @include dds-type-scale(1);

  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  letter-spacing: rem(-0.68px);
  margin-right: $dds-spacing-2;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(3);

    margin-right: $dds-spacing-2;
  }
}

.PastDealsPage-ClearButton {
  @include dds-type-scale(-2);

  color: var(--dds-gray-500);
  font-weight: $dds-font-weight-semibold;
  text-transform: none;

  &.Button--Small {
    svg {
      fill: var(--dds-gray-500);
      margin-right: $dds-spacing-0_25;
    }
  }

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(-1);
  }
}

.PastDealsPage-Tabs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.PastDealsPage-TabListWrapper {
  position: relative;

  @include media-breakpoint-up(xl) {
    &::after {
      @include dds-pos(absolute, $top: 0, $right: 0);

      background: linear-gradient(270deg, var(--dds-white) 20%, rgb(255 255 255 / 0%) 100%);
      content: '';
      height: 100%;
      width: $dds-sizing-4;
    }
  }
}

.PastDealsPage-TabList {
  @include media-breakpoint-up(md) {
    margin-right: -$dds-spacing-3;
  }

  @include media-breakpoint-up(lg) {
    margin-right: -$dds-spacing-6;
  }

  @include media-breakpoint-up(xl) {
    margin-right: 0;
  }
}

.PastDealsPage-TabPanel {
  background-color: var(--dds-gray-100);
  flex-grow: 1;
}
