@import '~dd-client/site/common/styles/globals';

@mixin cds-loader-animation {
  animation: loader-animation-rotate 1.2s linear infinite;
}

@keyframes loader-animation-rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.Loader {
  @include dds-box($dds-sizing-12);

  &::after {
    @include cds-loader-animation;

    border-color: var(--dds-gray-300) transparent;
    border-radius: 50%;
    border-style: solid;
    border-width: $dds-sizing-1;
    content: ' ';
    display: inline-block;
    height: 100%;
    width: 100%;
  }
}

.Loader--Small {
  @include dds-box($dds-sizing-2);

  &::after {
    border-width: $dds-sizing-0_25;
  }
}
