@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.Description {
  @include dds-content-text-links;

  padding: $dds-spacing-5 0;

  @include media-breakpoint-up(md) {
    padding: $dds-spacing-6 0;
  }
}

.Description-ContentWrapper {
  @include media-breakpoint-down(md) {
    order: 2;
  }
}

.Description-Content {
  @include dds-tabs-content;
}

.Description-Author {
  margin-top: $dds-spacing-4;

  @include media-breakpoint-up(md) {
    @include dds-type-scale(1);
  }
}

.Description-AuthorImage {
  border: 1px solid var(--dds-gray-200);
  border-radius: 50%;
  margin-right: $dds-spacing-1;

  @include media-breakpoint-down(md) {
    height: $dds-sizing-4;
    width: $dds-sizing-4;
  }
}

.Description-TeaserImageWrapper {
  text-align: center;

  @include media-breakpoint-down(md) {
    margin-bottom: $dds-spacing-4;
    order: 1;
  }
}

.Description-TeaserImage {
  max-width: 100%;

  @include media-breakpoint-up(lg) {
    padding: 0 $dds-spacing-8;
  }
}
