@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

$accessories-product-item-desktop-image-max-width: 90px;

.AccessoriesProductItem {
  align-items: center;
  display: flex;
  margin-bottom: $dds-spacing-4;
  overflow: hidden;
  width: 100%;

  &:first-child {
    margin-top: $dds-spacing-1_5;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    text-decoration: none;
  }
}

.AccessoriesProductItem-LinkImage {
  margin-right: $dds-spacing-2;

  @include media-breakpoint-up(md) {
    margin-right: $dds-spacing-3;
  }
}

.AccessoriesProductItem-Image {
  max-width: $dds-spacing-8;

  @include media-breakpoint-up(md) {
    max-width: $accessories-product-item-desktop-image-max-width;
  }
}

.AccessoriesProductItem-DescWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include media-breakpoint-up(lg) {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
}

.AccessoriesProductItem-Labels {
  margin-bottom: $dds-spacing-0_5;
  padding: $dds-spacing-0_5 0;
}

.AccessoriesProductItem-Title {
  @include dds-type-scale(-1);
  @include dds-link;

  align-self: stretch;
  display: inline-block;
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  letter-spacing: rem(-0.43px);
  line-height: $dds-line-height-list;
  margin-bottom: $dds-spacing-0_5;

  @include media-breakpoint-up(md) {
    @include dds-type-scale(0);

    letter-spacing: rem(-0.48px);
    line-height: $dds-line-height-list;
    margin-bottom: $dds-spacing-0_5;
  }

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(2);

    letter-spacing: rem(-0.61px);
    margin-bottom: $dds-spacing-0_5;
  }
}

.AccessoriesProductItem-Stock {
  @include dds-type-scale(-1);

  color: var(--dds-gray-500);
  line-height: $dds-line-height-list;
  margin-bottom: $dds-spacing-1;

  @include media-breakpoint-up(md) {
    margin-bottom: $dds-spacing-0_5;
  }

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(0);

    line-height: $dds-line-height-list;
    margin-bottom: 0;
  }
}

.AccessoriesProductItem-PriceButtonWrapper {
  align-items: center;
  display: flex;
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  justify-content: space-between;

  @include media-breakpoint-up(lg) {
    margin-left: $dds-spacing-3;
  }
}

.AccessoriesProductItem-PriceWrapper {
  align-items: baseline;
  display: flex;
}

.AccessoriesProductItem-Price {
  @include dds-type-scale(1);

  letter-spacing: rem(-0.54px);
  margin-right: $dds-spacing-0_5;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(3);

    letter-spacing: rem(-0.68px);
    margin-right: $dds-spacing-0_5;
  }
}

.AccessoriesProductItem-OldPrice {
  @include dds-type-scale(0);

  color: var(--dds-gray-400);
  letter-spacing: rem(-0.48px);
  margin-right: $dds-spacing-0_5;
  text-decoration: line-through;
}

.AccessoriesProductItem-Button {
  margin-left: $dds-spacing-0_5;

  &.Button {
    width: $dds-sizing-8;
  }
}
