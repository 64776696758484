@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.Footer-TopWrapper {
  align-items: flex-start;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  order: 0;

  @include media-breakpoint-up(md) {
    order: 1;
  }

  @include media-breakpoint-up(lg) {
    align-items: flex-end;
    flex-basis: auto;
    order: 0;
  }
}

.Footer-TopLinkList {
  @include dds-list-unstyled;

  margin: 0 auto $dds-spacing-6;
  text-align: center;

  li {
    display: block;
    margin-bottom: $dds-spacing-3;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(md) {
    margin: 0 0 $dds-spacing-8;

    li {
      display: inline-block;
      margin-bottom: 0;

      &:not(:last-child) {
        margin-right: $dds-spacing-4;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: $dds-spacing-6;
  }
}

.Footer-TopIconList {
  @include dds-list-unstyled;

  align-items: center;
  display: none;

  li {
    margin-right: $dds-spacing-4;

    &:last-child {
      margin-right: 0;
    }
  }

  @include media-breakpoint-up(md) {
    display: flex;
    margin-bottom: $dds-spacing-8;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }
}
