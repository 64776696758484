@import '~dd-client/site/common/styles/globals';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import 'node_modules/bootstrap/scss/mixins';

.WriteCommentForm {
  background-color: var(--dds-common-write-comment-form-bg);
  border: 1px solid var(--dds-common-write-comment-form-border-color);
  border-radius: $dds-border-radius-global * 2;
  padding: $dds-sizing-2;

  .TextInput,
  .TextArea {
    margin-bottom: $dds-spacing-4;
  }
}

.WriteCommentForm-Title {
  @include dds-type-scale(1);

  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  letter-spacing: rem(-0.54px);
  margin-bottom: $dds-spacing-4;
}

.WriteCommentForm-NameEmailWrapper {
  column-gap: $dds-spacing-2;
  display: flex;
  flex-direction: column;
  margin-bottom: -$dds-spacing-1;

  .TextInput {
    flex: 1 1 50%;
  }

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.WriteCommentForm-ButtonsWrapper {
  display: flex;
  justify-content: space-between;
}

.WriteCommentForm-Loader {
  &::after {
    border-color: var(--dds-white) transparent;
  }
}

.WriteCommentForm-Alert {
  margin-bottom: $dds-spacing-4;
}


.WriteCommentForm-ReCaptcha {
  /* stylelint-disable-next-line selector-class-pattern */
  .grecaptcha-badge {
    height: 0 !important; /* stylelint-disable-line declaration-no-important */
    visibility: hidden;
  }
}

.WriteCommentForm-ReCaptchaInfo {
  @include dds-type-scale(-3, 0.5, $dds-line-height-condensed);

  color: var(--dds-gray-500);

  a {
    @include dds-link(var(--dds-gray-500));

    text-decoration: underline;
  }
}
