@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

$panel-border: 1px solid var(--dds-gray-100);

.CookieOptOutPage {
  padding-top: $dds-spacing-2;
  text-align: center;

  @include media-breakpoint-up(md) {
    padding-top: $dds-spacing-4;
  }

  @include media-breakpoint-up(lg) {
    padding-top: $dds-spacing-6;
  }
}

.CookieOptOutPage-Title {
  margin-bottom: $dds-spacing-2;
}

.CookieOptOutPage-Icon {
  margin-bottom: $dds-spacing-2;
}

.CookieOptOutPage-Content {
  margin: auto;
  max-width: $dds-sizing-86;
  text-align: left;
  width: 100%;
}

.CookieOptOutPage-Text {
  margin-bottom: $dds-spacing-3;
}

.CookieOptOutPage-Panel {
  align-items: center;
  border-bottom: $panel-border;
  border-top: $panel-border;
  column-gap: $dds-spacing-1;
  display: flex;
  font-weight: $dds-font-weight-semibold;
  margin-bottom: $dds-spacing-12;
  padding-bottom: $dds-spacing-2;
  padding-top: $dds-spacing-2;
}

