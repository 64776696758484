@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.Stickers {
  display: flex;
  flex-direction: column;
  margin-top: $dds-spacing-1;
  position: absolute;
  width: auto;

  @include media-breakpoint-up(md) {
    margin-top: 0;
    padding: 0;
  }
}

.Stickers-ImageWrapper {
  margin-top: $dds-spacing-1;
  width: $dds-sizing-8;

  @include media-breakpoint-up(md) {
    &:first-child {
      margin-top: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    width: $dds-sizing-10;

    &:not(:first-child) {
      margin-top: $dds-spacing-2;
    }
  }
}

.Stickers-Image {
  max-width: 100%;
}
