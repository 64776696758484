@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/globals';

$ease-out-quad: cubic-bezier(0.5, 1, 0.89, 1);
$image-radius: $dds-sizing-1;
$promotion-margin: $dds-spacing-4;

.Promotion {
  @include dds-pos(fixed, $bottom: $promotion-margin, $right: $promotion-margin);

  border-radius: $image-radius;
  box-shadow: 0 0 $dds-spacing-3 0 rgb(0 0 0 / 25%);
  height: $dds-sizing-24;
  width: $dds-sizing-48;
  z-index: $dds-zindex-promotion;
}

.Promotion-CloseIcon {
  @include dds-pos(absolute, $top: - $dds-spacing-6, $right: 0);

  cursor: pointer;
  fill: var(--dds-gray-600);
  height: $dds-sizing_5;
  width: $dds-sizing_5;
}

.Promotion-Image {
  border-radius: $image-radius;
  max-width: $dds-sizing-48;
}

.Promotion--NonClickable {
  a {
    cursor: default;
  }
}

/* Animation
   ========================================================================== */

.Promotion-Animation {
  animation: fade-in 400ms $ease-out-quad forwards;

  &.Promotion-Animation--Close {
    animation: fade-out 100ms $ease-out-quad forwards;
  }
}

@keyframes fade-in {
  from {
    bottom: -100%;
    opacity: 0;
  }

  to {
    bottom: $promotion-margin;
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
