@import '~dd-client/site/common/styles/globals';

.Button {
  align-items: center;
  border-radius: $dds-border-radius-global;
  border-width: 0;
  cursor: pointer;
  display: flex;
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  justify-content: center;
  outline: none;
  position: relative;
  text-transform: uppercase;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:focus {
    outline: none;
  }
}

.Button-Icon--Left {
  img,
  svg {
    margin-right: $dds-spacing-1;
  }
}

.Button-Icon--Right {
  img,
  svg {
    margin-left: $dds-spacing-1;
    order: 1;
  }
}

.Button--FullWidth {
  width: 100%;
}

// Button sizes

.Button--Default {
  @include dds-type-scale(body);

  padding: $dds-spacing-1 $dds-spacing-2;

  img,
  svg {
    height: $dds-sizing-3;
    width: $dds-sizing-3;
  }
}

.Button--Small {
  @include dds-type-scale(body-sm);

  line-height: $dds-line-height-base;
  padding: $dds-spacing-0_5 $dds-spacing-2;

  img,
  svg {
    height: $dds-sizing-2;
    width: $dds-sizing-2;
  }

  &.Button-Icon--Left {
    .Loader,
    img,
    svg {
      margin-right: $dds-spacing-0_5;
    }
  }

  &.Button-Icon--Right {
    .Loader,
    img,
    svg {
      margin-left: $dds-spacing-0_5;
    }
  }
}

// Button style types

.Button--Primary {
  @include dds-button(
    $bg: var(--dds-common-button-primary-bg),
    $bg-hover:var(--dds-common-button-primary-bg-hover),
    $bg-active: var(--dds-common-button-primary-bg-active),
    $color: var(--dds-common-button-primary-color),
  );

  svg {
    fill: var(--dds-common-button-primary-color);
  }
}

.Button--Secondary {
  @include dds-button(
    $bg: var(--dds-common-button-secondary-bg),
    $bg-hover: var(--dds-common-button-secondary-bg-hover),
    $bg-active: var(--dds-common-button-secondary-bg-active),
    $border: var(--dds-common-button-secondary-border-color),
    $color: var(--dds-common-button-secondary-color),
    $color-hover: var(--dds-common-button-secondary-color-hover),
    $color-active: var(--dds-common-button-secondary-color-active),
  );

  svg {
    fill: var(--dds-common-button-secondary-color);
  }

  &:hover {
    svg {
      fill: var(--dds-common-button-secondary-color-hover);
    }
  }

  &:active {
    svg {
      fill: var(--dds-common-button-secondary-color-active);
    }
  }
}

.Button--Light {
  @include dds-button(
    $bg: var(--dds-common-button-light-bg),
    $bg-hover: var(--dds-common-button-light-bg-hover),
    $bg-active: var(--dds-common-button-light-bg-active),
    $border: var(--dds-common-button-light-border-color),
    $color: var(--dds-common-button-light-color),
  );

  svg {
    fill: var(--dds-common-button-light-svg-fill);
  }
}

.Button--Ghost {
  background-color: transparent;
  box-shadow: none;
  color: var(--dds-primary);
  padding: 0;

  svg {
    fill: var(--dds-primary);
  }
}
