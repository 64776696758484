@import 'node_modules/bootstrap/scss/mixins';
@import '~react-image-gallery/styles/scss/image-gallery';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

/* stylelint-disable selector-class-pattern */
.Gallery {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  outline: none;

  .image-gallery,
  .image-gallery-content {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .image-gallery {
    margin-bottom: $dds-spacing-1_5;
  }

  .image-gallery-slide-wrapper {
    align-items: center;
    display: flex;
    flex: 1;
    margin-bottom: $dds-spacing-3;

    @include media-breakpoint-up(lg) {
      padding: 0 $dds-spacing-4;
    }
  }

  .image-gallery-swipe {
    margin: 0 auto;
    width: 100%;
  }

  .image-gallery-slides {
    width: 100%;
  }

  .image-gallery-slide {
    cursor: unset;

    .image-gallery-image {
      max-height: calc(100vh - 410px); // added to prevent too big images, scrolling on horizontal screens
      max-width: 100%;
      width: auto;
    }
  }

  .image-gallery-thumbnails {
    padding: $dds-spacing-0_5 0;
  }

  .image-gallery-thumbnail {
    @include dds-box($dds-sizing-8);

    border: 1px solid var(--dds-deal-deal-page-product-details-description-media-item-border-color);
    border-radius: $dds-border-radius-global;
    overflow: hidden;
    position: relative;
    transition: none;

    &.active {
      border: 1px solid var(--dds-deal-deal-page-product-details-description-media-item-border-color-hover);

      &::after {
        @include dds-pos(absolute, $top: 0, $bottom: 0, $left: 0, $right: 0);

        border: 3px solid var(--dds-deal-deal-page-product-details-description-media-item-border-color-hover);
        content: '';
      }
    }

    &:hover {
      border: 1px solid var(--dds-deal-deal-page-product-details-description-media-item-border-color-hover);
    }

    + .image-gallery-thumbnail {
      margin-left: $dds-spacing-2;
    }

    @include media-breakpoint-up(md) {
      @include dds-box($dds-sizing-15);

      + .image-gallery-thumbnail {
        margin-left: $dds-spacing-4;
      }
    }
  }

  .image-gallery-thumbnail-image {
    max-height: calc(#{$dds-sizing-8} - 2px);
    max-width: calc(#{$dds-sizing-8} - 2px);
    width: auto;

    @include media-breakpoint-up(md) {
      max-height: calc(#{$dds-sizing-15} - 2px);
      max-width: calc(#{$dds-sizing-15} - 2px);
    }
  }

  .Gallery-ThumbnailPlay {
    @include dds-box($dds-sizing-3);
    @include dds-pos(absolute, 50%, 50%);

    align-items: center;
    background-color: var(--dds-deal-deal-page-product-details-description-media-item-play-bg);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    transform: translate(50%, -50%);

    svg {
      @include dds-box($dds-sizing-2);

      fill: var(--dds-text-body);
      margin-left: $dds-sizing-0_5; // fine-tuning
    }

    @include media-breakpoint-up(md) {
      @include dds-box($dds-sizing-4);
    }
  }
}

.Gallery-VideoWrapper {
  margin: 0 auto;
  max-width: calc(100vh - 135px); // added to prevent too big iframes, scrolling on horizontal screens
}

.Gallery-VideoIframe {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
}

.Gallery-Video {
  @include dds-pos(absolute, $left: 0, $top: 0, $bottom: 0, $right: 0);

  height: 100%;
  width: 100%;
}

.Gallery-Arrow {
  &.Button {
    @include dds-pos(absolute, $top: 50%);

    transform: translateY(-50%);
    z-index: 2;

    svg {
      @include dds-box($dds-sizing-4);

      background-color: var(--dds-white);
      fill: var(--dds-text-body);
    }

    @include media-breakpoint-up(lg) {
      height: $dds-sizing-25;
      width: $dds-sizing-12;
    }
  }
}

.Gallery-Arrow--Left {
  left: -$dds-spacing-1;

  @include media-breakpoint-up(lg) {
    left: -$dds-spacing-4;
  }
}

.Gallery-Arrow--Right {
  right: -$dds-spacing-1;

  @include media-breakpoint-up(lg) {
    right: -$dds-spacing-4;
  }
}

.Gallery-Label {
  @include dds-type-scale(1);

  margin: $dds-spacing-2 0;
  min-height: $dds-sizing-3;
  text-align: center;
}

.Gallery-Index {
  @include dds-type-scale(0);

  color: var(--dds-gray-400);
  text-align: center;
}
