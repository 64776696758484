@import '~dd-client/site/common/styles/globals';

.NewsletterErrorPage-Icon {
  fill: var(--dds-warning);
}

.NewsletterErrorPage-Title {
  margin-bottom: $dds-spacing-1;
}

.NewsletterErrorPage-Text {
  margin-bottom: $dds-spacing-6;
}
