@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.NoActiveDeals {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.NoActiveDeals-Title {
  padding-top: $dds-spacing-25;
}

.NoActiveDeals-Details {
  padding: $dds-spacing-2 $dds-spacing-2;
}

.NoActiveDeals-Button {
  margin-bottom: $dds-spacing-6;
  text-decoration: none;
}
