@import '~dd-client/site/common/styles/globals';

.LanguageSelector {
  margin-right: $dds-spacing-6;
}

.LanguageSelector-List {
  @include dds-list-unstyled;
}

.LanguageSelector-Item {
  &:not(:last-child) {
    margin-right: $dds-spacing-2;
  }
}

.LanguageSelector-Item--Active {
  font-weight: $dds-font-weight-bold;
}

.LanguageSelector-Link {
  @include dds-link;
}
