@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.Details {
  @include dds-tabs-content;
  @include dds-content-text-links;

  padding-bottom: $dds-spacing-3;
  padding-top: 0;

  @include media-breakpoint-up(md) {
    padding-bottom: $dds-spacing-6;
    padding-top: $dds-spacing-3;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: $dds-spacing-8;
    padding-top: $dds-spacing-6;
  }
}

.Details-Accordion {
  display: block;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.Details-ContentNavigation {
  display: none;

  @include media-breakpoint-only(md) {
    display: flex;
  }
}

.Details-TechnicalData {
  h3 {
    @include dds-font-size(0);

    font-weight: $dds-font-weight-bold;
    letter-spacing: rem(-0.54px);
    line-height: $dds-line-height-base;
    margin-bottom: 0;
    padding-bottom: $dds-spacing-1;

    @include media-breakpoint-up(md) {
      @include dds-font-size(1);
    }

    @include media-breakpoint-up(lg) {
      border-bottom: 1px solid var(--dds-gray-300);
      padding-top: $dds-spacing-1;
    }
  }

  ul {
    @at-root .Details & {
      margin-bottom: $dds-spacing-3;

      li {
        padding: $dds-spacing-0_25 0 $dds-spacing-0_5 $dds-spacing-3;

        &:last-child {
          border-bottom: 0;
        }

        @include media-breakpoint-up(md) {
          padding: $dds-spacing-1 0 $dds-spacing-1 $dds-spacing-3;
        }

        @include media-breakpoint-up(lg) {
          border-bottom: 1px solid var(--dds-gray-300);
        }
      }

      ul {
        margin-top: $dds-spacing-0_5;

        li {
          border: 0;
          padding: $dds-spacing-0_25 0 $dds-spacing-0_5 $dds-spacing-3;

          @include media-breakpoint-up(md) {
            padding-top: $dds-spacing-0_5;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  p {
    margin-bottom: $dds-spacing-2;

    @include media-breakpoint-up(md) {
      margin-bottom: $dds-spacing-3;
    }
  }
}

// Specification Desktop
.Details-Desktop {
  display: none;
  justify-content: space-between;

  @include media-breakpoint-up(lg) {
    display: flex;
  }
}

.Details-Desktop--LeftSide {
  flex: 0 0 50%;
}

.Details-DesktopHeader {
  @include dds-font-size(3);

  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  letter-spacing: rem(-0.68px);
  margin-bottom: $dds-spacing-2;
}

.Details-Desktop--RightSide {
  background-color: var(--dds-gray-100);
  flex: 0 0 40%;
  padding: $dds-spacing-3 $dds-spacing-4;
}

.Details-Desktop--ScopeOfDelivery {
  padding-bottom: $dds-spacing-3;
}

.Details-Desktop--Links {
  margin-bottom: $dds-spacing-3;
}
