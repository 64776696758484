@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

$product-main-image-mobiles-max-width: 320px;
$product-main-image-max-height: 520px;
$product-main-variants-modal-max-width: 1200px;

.ProductMain {
  display: grid;
  grid-template-areas:
  'category-name'
  'title'
  'subtitle'
  'image'
  'progress-bar'
  'price'
  'buy-button'
  'delivery-info'
  'sticky-bar'
  'bullets';
  grid-template-rows: auto;

  &--HomePage {
    grid-template-areas:
    'image'
    'progress-bar'
    'title'
    'subtitle'
    'price'
    'buy-button';
    grid-template-rows: auto;
  }

  @include media-breakpoint-up(md) {
    grid-template-areas:
    'image title'
    'image subtitle'
    'image bullets'
    'image price'
    'image buy-button'
    'image delivery-info'
    'progress-bar progress-bar'
    'sticky-bar sticky-bar';
    grid-template-columns: 53% 1fr;
    grid-template-rows: auto auto auto auto auto 1fr auto;
    padding-top: $dds-spacing-5;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 58% 1fr;
    padding-top: $dds-spacing-6;
  }
}

.ProductMain-CategoryName {
  @include dds-type-scale(-2);

  color: var(--dds-gray-400);
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  grid-area: category-name;
  letter-spacing: rem(-0.38px);
  padding: $dds-spacing-1_5 0 $dds-spacing-1;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.ProductMain-ImageWrapper {
  align-self: end;
  grid-area: image;
  margin-left: -$dds-spacing-2;
  margin-right: -$dds-spacing-2;
  position: relative;

  @include media-breakpoint-up(md) {
    margin-left: 0;
    margin-right: $dds-spacing-3;
  }
}

.ProductMain-ImageLoader {
  @include dds-pos(absolute, $left: 0, $top: 0, $bottom: 0, $right: 0);

  margin: auto;
}

.ProductMain-Image {
  display: block;
  margin: 0 auto;
  max-height: $product-main-image-max-height;
  max-width: $product-main-image-mobiles-max-width;

  @include media-breakpoint-up(sm) {
    max-width: 100%;
  }
}

.ProductMain-Image--Loading {
  opacity: 0.1;
}

.ProductMain-Title {
  @include dds-type-scale(3);

  grid-area: title;
  letter-spacing: rem(-0.68px);

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(8);

    letter-spacing: rem(-1.23px);
  }
}

.ProductMain-Subtitle {
  @include dds-type-scale(body-lg);

  color: var(--dds-deal-deal-page-product-main-subtitle-color);
  grid-area: subtitle;
  letter-spacing: rem(-0.54px);
  margin-bottom: $dds-spacing-2;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(6);

    letter-spacing: rem(-0.97px);
    margin-bottom: $dds-spacing-3;
  }
}

.ProductMain-BulletPoints {
  grid-area: bullets;
}

.ProductMain-BuyButton {
  grid-area: buy-button;
}

.ProductMain-SeeDealButton {
  grid-area: buy-button;
}

.ProductMain-SoldOutInfo {
  grid-area: buy-button;
}

.ProductMain-ReservedInfo {
  grid-area: buy-button;

  .Alert {
    @include dds-type-scale(0);

    @include media-breakpoint-up(md) {
      @include dds-type-scale(-1);
    }

    @include media-breakpoint-up(lg) {
      @include dds-type-scale(0);
    }
  }
}

.ProductMain-DeliveryInfo {
  grid-area: delivery-info;
}

.ProductMain-ProgressBar {
  grid-area: progress-bar;
  margin-bottom: $dds-spacing-2;

  @include media-breakpoint-up(md) {
    margin-bottom: $dds-spacing-5;
  }
}

.ProductMain-Price {
  grid-area: price;
  margin-bottom: $dds-spacing-2;

  @include media-breakpoint-up(lg) {
    margin-bottom: $dds-spacing-3;
  }
}

.ProductMain-VariantsModal {
  max-width: $product-main-variants-modal-max-width;
  padding: 0;
}

.ProductMain-Sticky {
  grid-area: sticky-bar;
}
