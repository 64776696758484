@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

$tab-list-border-size:     1px;
$tab-selected-border-size: $dds-spacing-0_5;

.Tabs-TabList {
  @include dds-list-unstyled;

  border-bottom: $tab-list-border-size solid var(--dds-gray-300);
  display: flex;
  margin-right: -$dds-spacing-2;
  -ms-overflow-style: none;
  overflow-x: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include media-breakpoint-up(md) {
    margin-right: 0;
  }
}

.Tabs-Tab {
  @include dds-type-scale(body-sm);

  cursor: pointer;
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-extrabold;
  margin-right: $dds-spacing-2;
  outline: none;
  padding-bottom: $dds-spacing-1_5;
  text-transform: uppercase;
  white-space: nowrap;

  @include media-breakpoint-up(lg) {
    margin-right: $dds-spacing-4;
  }
}

.Tabs-Tab--Selected {
  border-bottom: $tab-selected-border-size solid var(--dds-primary);
  color: var(--dds-primary);
  padding-bottom: $dds-spacing-1;
}
