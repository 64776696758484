@import '~dd-client/site/common/styles/globals';

.CommentBox-Top {
  @include dds-type-scale(-1);

  display: flex;
  justify-content: space-between;
  margin-bottom: $dds-spacing-0_5;

  svg {
    @include dds-box($dds-sizing-3);

    fill: var(--dds-gray-400);
  }
}

.CommentBox-AuthorAndTime {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.CommentBox-AuthorName {
  margin-right: $dds-spacing-1;
}

.CommentBox-Time {
  color: var(--dds-common-comment-box-time-color);
}

.CommentBox-Avatar {
  @include dds-box($dds-sizing-4);

  border: 1px solid var(--dds-gray-200);
  border-radius: 50%;
  margin-right: $dds-spacing-1;
}

.CommentBox-Badge {
  @include dds-type-scale(-2);

  background-color: var(--dds-gray-400);
  border-radius: $dds-border-radius-global;
  color: var(--dds-white);
  font-weight: $dds-font-weight-bold;
  letter-spacing: rem(-0.65px);
  line-height: $dds-line-height-list;
  margin-right: $dds-spacing-1;
  padding: 0 $dds-spacing-0_5;
}

.CommentBox-Title {
  @include dds-type-scale(0);

  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  letter-spacing: rem(-0.48px);
  margin-bottom: $dds-spacing-0_5;
}

.CommentBox-Content {
  @include dds-type-scale(0);
  @include dds-content-text-links;

  word-break: break-word;
}

.CommentBox-ReplyCount {
  @include dds-type-scale(-1);

  align-items: center;
  color: var(--dds-gray-600);
  display: flex;
  margin-top: $dds-spacing-2;
}

.CommentBox-ReplyIcon {
  @include dds-box($dds-sizing-3);

  fill: var(--dds-gray-400);
  margin-right: $dds-spacing-0_5;
}
