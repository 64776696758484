@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.ErrorPage {
  text-align: center;
}

.ErrorPage-Image {
  max-width: 100%;
}

.ErrorPage-Video {
  display: block;
  margin: auto;
  max-height: $dds-sizing-30;
  max-width: 100%;

  @include media-breakpoint-up(lg) {
    max-height: 100%;
  }
}

.ErrorPage-Details {
  background-color: var(--dds-gray-100);
  padding: $dds-spacing-8 $dds-spacing-2;
}

.ErrorPage-Button {
  margin: $dds-spacing-3 auto 0;
}
