@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.Footer {
  background-color: var(--dds-app-footer-bg);
  padding-bottom: $dds-spacing-3;
  padding-top: $dds-spacing-4;

  @include media-breakpoint-up(md) {
    padding-bottom: $dds-spacing-8;
    padding-top: $dds-spacing-7;
  }

  @include media-breakpoint-up(lg) {
    padding-top: $dds-spacing-8;
  }
}

.Footer--HomePage {
  @include media-breakpoint-down(md) {
    background-color: var(--dds-white);
  }
}

.Footer--White {
  background-color: var(--dds-white);
}
