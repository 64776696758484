@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.ContentNavigation-Title {
  @include dds-font-size(0);

  align-items: center;
  border-bottom: 1px solid var(--dds-gray-300);
  cursor: pointer;
  display: flex;
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  justify-content: space-between;
  letter-spacing: rem(-0.48px);
  padding: $dds-spacing-2 0;

  svg {
    @include dds-box($dds-sizing-3);

    fill: var(--dds-gray-400);
  }

  @include media-breakpoint-up(lg) {
    @include dds-font-size(2);

    letter-spacing: rem(-0.61px);
    padding: $dds-spacing-1_5 0;
  }
}

.ContentNavigation-Title--Open {
  color: var(--dds-primary);

  svg {
    fill: var(--dds-primary);
  }
}
