@import '~dd-client/site/common/styles/globals';

.StaticPromotion {
  background-color: var(--dds-neon-yellow);
  padding: $dds-spacing-3 $dds-spacing-2 $dds-spacing-4;
}

.StaticPromotion-Title {
  @include dds-type-scale(3);

  font-weight: $dds-font-weight-bold;
  margin-bottom: $dds-spacing_2;
}

.StaticPromotion-Link {
  display: block;
  text-align: center;
}

.StaticPromotion-Image {
  max-width: 100%;
}
