@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.Slider-Arrow {
  @include dds-pos(absolute, calc(100% - #{$dds-spacing-2_75}));

  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0;
  z-index: 2;

  @include media-breakpoint-up(lg) {
    @include dds-pos(absolute, calc(50% - #{$dds-spacing-4_5 * 0.5}));

    transform: translateY(-50%);
  }
}

.Slider-ArrowIcon {
  @include dds-box($dds-sizing-4);

  fill: var(--dds-primary);
}

.Slider-Arrow--Next {
  right: 0;

  @include media-breakpoint-up(lg) {
    right: -$dds-spacing-4;
  }
}

.Slider-Arrow--Prev {
  left: 0;

  @include media-breakpoint-up(lg) {
    left: -$dds-spacing-4;
  }
}

.Slider-Arrow--Disabled {
  cursor: auto;
  opacity: 0.5;
  pointer-events: none;
}
