@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

$recommended-product-item-image-width: 211px;

.RecommendedProductItem {
  @include dds-link;

  align-items: flex-start;
  border: 1px solid var(--dds-deal-deal-page-recommended-products-item-border-color);
  border-radius: $dds-border-radius-global;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: $dds-spacing-2;

  &:hover {
    text-decoration: none;
  }
}

.RecommendedProductItem-Labels {
  margin-bottom: $dds-spacing-1;
  min-height: $dds-spacing-1_5;
}

.RecommendedProductItem-Image {
  margin: 0 auto $dds-spacing-2;
  max-width: 100%;
  width: $recommended-product-item-image-width;
}

.RecommendedProductItem-Manufacturer {
  @include dds-font-size(small);
  @include dds-text-ellipsis;

  color: var(--dds-deal-deal-page-recommended-products-item-manufacturer-color);
  margin-bottom: $dds-spacing-0_5;
}

.RecommendedProductItem-Title {
  @include dds-text-ellipsis;
  @include dds-type-scale(0);

  margin-bottom: $dds-spacing-0_5;

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(2);

    margin-bottom: $dds-spacing-0_5;
  }
}

.RecommendedProductItem-Subtitle {
  @include dds-text-ellipsis;

  margin-bottom: $dds-spacing-0_5;

  @include media-breakpoint-up(md) {
    margin-bottom: $dds-spacing-1;
  }
}

.RecommendedProductItem-BottomWrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;
  width: 100%;
}

.RecommendedProductItem-Stock {
  @include dds-font-size(small);

  color: var(--dds-deal-deal-page-recommended-products-item-stock-count-color);
  flex-basis: 100%;
  margin-bottom: $dds-spacing-1_5;

  @include media-breakpoint-up(md) {
    @include dds-font-size(body);

    flex-basis: auto;
    margin-bottom: 0;
  }
}

.RecommendedProductItem-PriceWrapper {
  align-items: baseline;
  display: flex;
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  margin-left: auto;
}

.RecommendedProductItem-Price {
  @include dds-font-size(2);

  letter-spacing: rem(-0.61px);
}

.RecommendedProductItem-OldPrice {
  @include dds-font-size(0);

  color: var(--dds-deal-deal-page-recommended-products-item-old-price-color);
  letter-spacing: rem(-0.48px);
  margin-left: $dds-spacing-0_5;
  text-decoration: line-through;
}

.RecommendedProductItem-Button {
  margin-left: $dds-spacing-1;
}
