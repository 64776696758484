@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

$modal-animation-duration: $dds-transition-timing;
$modal-max-width: map-get($container-max-widths, 'xl');

.Modal-Overlay {
  @include dds-pos(fixed, 0, 0, 0, 0);

  background-color: var(--dds-common-modal-overlay-bg);
  height: 100%;
  width: 100%;
  z-index: $dds-zindex-modal-overlay;
}

.Modal {
  @include dds-pos(fixed, $top: 0, $left: 0);

  background-color: var(--dds-common-modal-bg);
  height: 100%;
  overflow-y: auto;
  padding: $dds-spacing-4 $dds-spacing-1;
  width: 100%;
  z-index: $dds-zindex-modal;

  @include media-breakpoint-up(md) {
    @include dds-pos(fixed, $top: 50%, $left: 50%);

    border-radius: $dds-border-radius-global * 2;
    box-shadow: $dds-box-shadow;
    height: auto;
    max-height: calc(100% - #{$dds-spacing-6});
    max-width: $modal-max-width;
    padding: $dds-spacing-4;
    transform: translate(-50%, -50%);
    width: calc(100% - #{$dds-spacing-6});
  }

  @include media-breakpoint-up(lg) {
    max-height: calc(100% - #{$dds-spacing-12});
    width: calc(100% - #{$dds-spacing-12});
  }
}

.Modal-Close {
  @include dds-pos(absolute, $top: $dds-spacing-2, $right: $dds-spacing-2);

  svg {
    @include dds-box($dds-sizing-4);

    fill: var(--dds-text-body);
  }
}

/* Animation
   ========================================================================== */

.Modal-Animation--Fade {
  animation: fade-in #{$modal-animation-duration} ease-out forwards;

  &.Modal-Animation--Close {
    animation: fade-out #{$modal-animation-duration} ease-in forwards;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.Modal-Animation--SlideRight {
  animation: fade-in #{$modal-animation-duration} ease-out forwards;

  .Modal {
    @include dds-pos(fixed, 0, 0, 0, auto);

    animation: slide-right #{$modal-animation-duration} ease-out forwards;
    max-height: none;
    transform: none;
    width: 100%;
  }

  &.Modal-Animation--Close {
    animation: fade-out #{$modal-animation-duration} ease-in forwards;

    .Modal {
      animation: slide-right-back #{$modal-animation-duration} ease-out forwards;
    }
  }
}


@keyframes slide-right {
  from { right: -100%; }
  to { right: 0; }
}

@keyframes slide-right-back {
  from { right: 0; }
  to { right: -100%; }
}
