@import '~dd-client/site/common/styles/globals';

.Countdown {
  @include dds-type-scale(-4);

  align-items: center;
  display: flex;
  font-family: $dds-font-family-secondary;
  font-weight: $dds-font-weight-bold;
  text-transform: uppercase;

  svg {
    @include dds-box($dds-sizing-1_5);

    fill: var(--dds-text-body);
    margin-right: $dds-spacing-0_25;
  }

  &.Countdown--Highlighted {
    svg {
      fill: var(--dds-common-countdown-highlighted-color);
    }

    .Countdown-Timer {
      color: var(--dds-common-countdown-highlighted-color);
    }
  }
}

.Countdown-Timer {
  /* stylelint-disable property-no-vendor-prefix */
  -moz-font-feature-settings: 'tnum';
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
}
