@import 'node_modules/bootstrap/scss/mixins';
@import '~dd-client/site/common/styles/vendor/bootstrap-custom-variables';
@import '~dd-client/site/common/styles/globals';

.BulletPoints {
  @include dds-type-scale(1);
  @include dds-list-unstyled(false);

  margin-bottom: $dds-spacing-4;

  li {
    margin-bottom: $dds-spacing-1;

    &::before {
      content: '•';
      margin-right: $dds-spacing-1_5;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @at-root .ProductMain--HomePage & {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  @include media-breakpoint-up(md) {
    @include dds-type-scale(-1);

    margin-bottom: $dds-spacing-3;

    li {
      margin-bottom: 0;

      &::before {
        margin-right: $dds-spacing-0_5;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    @include dds-type-scale(1);

    margin-bottom: $dds-spacing-4;

    li {
      margin-bottom: $dds-spacing-1;

      &::before {
        margin-right: $dds-spacing-1_5;
      }
    }
  }
}
